export const demo = () => {
  const width = document.documentElement.clientWidth
  const height = document.documentElement.clientHeight
  const portrait = width < height
  const landscape = height < width
  const margin = 0
  let layout = []
  let index = 0
  var itemW
  var itemH

  if (landscape) {
    itemW = width / 4 / 8
    itemH = height / 3 / 8
    const names = ["Melodic 1", "Melodic 2", "Melodic 3", "Melodic 4", "Tom 1", "Tom 2", "Ride", "Crash", "Kick", "Snare", "Closed HH", "Open HH"]
    const notes = ["44", "45", "46", "47", "40", "41", "42", "43", "36", "37", "38", "39"]
    const colors = ["#444444", "#555555", "#444444", "#555555", "#555555", "#444444", "#555555", "#444444", "#444444", "#555555", "#444444", "#555555"]
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 4; j++) {
        index++
        let row
        row = {
          "i": "n" + index,
          "w": itemW - margin,
          "h": itemH - margin,
          "x": itemW * j + margin * j/2,
          "y": itemH * i + margin * i/2,
          "component": "Pad",
          "content": {
            "text": names[index-1],
            "channel": 1,
            "note": parseInt(notes[index]),
            "value": 127,
            "velocity": "fixed",
            "bg": colors[index-1],
            "bgactive": "#999999"
          }
        }
        layout.push(row)
      }
    }
  }
  if (portrait) {
    itemW = width / 3 / 8
    itemH = height / 4 / 8
    const names = ["Melodic 1", "Melodic 2", "Melodic 3", "Melodic 4", "Tom 1", "Tom 2", "Ride", "Crash", "Kick", "Open HH", "Closed HH", "Snare"]
    const notes = ["44", "45", "46", "47", "40", "41", "42", "43", "36", "39", "38", "37"]
    const colors = ["#444444", "#555555", "#444444", "#555555", "#444444", "#555555", "#444444", "#555555", "#444444", "#555555", "#444444", "#555555"]
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 3; j++) {
        index++
        let row
        row = {
          "i": "n" + index,
          "w": itemW - margin,
          "h": itemH - margin,
          "x": itemW * j + margin * j/2,
          "y": itemH * i + margin * i/2,
          "component": "Pad",
          "content": {
            "text": names[index-1],
            "channel": 1,
            "note": parseInt(notes[index]),
            "value": 127,
            "velocity": "fixed",
            "bg": colors[index-1],
            "bgactive": "#999999"
          }
        }
        layout.push(row)
      }
    }
  }

  

  let data = {
    name: 'Demo',
    author: '',
    bg: '#333333',
    layout: layout,
  }
  return data
}


export default demo