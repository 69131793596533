import React from 'react'
// import { CSSGlobalVariables } from 'css-global-variables'
import { SketchPicker } from 'react-color'
import { Button, Popover, Input } from 'antd'
// import ErrorBoundary from './ErrorBoundary'

import Icon from '@ant-design/icons'

const SwatchSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 1024 1024"><circle cx="512" cy="512" r="482" fill="currentColor" stroke="rgba(255,255,255,0.3)" strokeWidth="40" fillRule="evenodd"/></svg>
)
const SwatchIcon = props => <Icon component={SwatchSvg} {...props} />

export default class ColorPicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      currentColor: this.props.currentColor,
      colors: this.props.colors || [
        '#ffffff',
        '#dddddd',
        '#999999',
        '#666666',
        '#444444',
        '#333333',
        '#222222',
        '#000000',
      ],
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.colorInput = React.createRef();
  }

  handleChange = thisColor => {
    var value = {
      target: {
        name: this.props.name,
        value: thisColor.hex,
      },
    }
    this.props.onColorChoose(value)
    this.setState({
      currentColor: thisColor.hex,
    })
  }

  handleInputChange = e => {
    this.setState({
      currentColor: e.target.value,
    })
    var value = {
      target: {
        name: e.target.name,
        value: e.target.value,
      },
    }
    this.props.onColorChoose(value)
  }

  handleAddColor = () => {
    this.setState({
      colors: this.state.colors.concat(this.state.currentColor),
    })
    var value = {
      target: {
        name: "colors",
        value: this.state.colors.concat(this.state.currentColor),
      },
    }
    this.props.onColorChoose(value)
  }

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  hidePopover = () => {
    this.setState({
      visible: false,
    });
  };

  showPopover = () => {
    this.setState({
      visible: true,
    });
  };

  render() {
    return (
      <div style={{
        display: 'flex',
        border: '1px solid rgba(255, 255, 255, 0.1)'
      }}>

        <Input
          ref={this.colorInput}
          type="string"
          name={this.props.name}
          value={this.state.currentColor}
          onChange={e => this.handleInputChange({
                      target: {
                        name: e.target.name,
                        value: e.target.value,
                      },
                    })
                  }
          className="input-color"
          onClick={this.showPopover}
        />
        <Popover
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
          transitionName="fade"
          maskTransitionName="fade"
          placement="topRight"
          className="color-popover"
          overlayClassName="color-popover-overlay"
          content={
            <div>
              <SketchPicker
                color={this.state.currentColor}
                presetColors={this.state.colors}
                onChangeComplete={color => this.handleChange(color)}
              />
              {/* <Button
                type="primary" 
                block 
                onClick={() => this.handleAddColor()}>
                add to colors
              </Button> */}
            </div>

          }
          trigger="click"
        >
          <Button
            size="large"
            type="primary"
            style={{
              background: 'transparent',
              color: 'currentColor',
              border: 'none',
              boxShadow: 'none',
              padding: '2px 0 0 8px'
            }}
            icon={
              <SwatchIcon 
                style={{
                  color: this.state.currentColor,
                  fontSize: '1.5em',
                  }}
                />}
          > </Button>
        </Popover>
        {/* <CirclePicker
          color={this.state.currentColor}
          colors={this.state.colors}
          width="100%"
          onChangeComplete={color => this.handleChange(color)}
        /> */}
      </div>
    )
  }
}

