import React from 'react'

import styled from 'react-emotion'

export class Text extends React.Component {

  render() {   
    return (
      <TextWrapper style={{
        background: this.props.bg, 
        color: this.props.bgactive, 
        fontFamily: 'IBM Plex Mono', 
        // fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
        fontSize: '14px'}}>
        {this.props.text}
      </TextWrapper>
    )
  }
}


const TextWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  ${'' /* padding: 1em; */}
`