export const manyfaders = () => {
  const width = document.documentElement.clientWidth
  const height = document.documentElement.clientHeight
  const portrait = width < height
  const landscape = height < width
  const longSide = Math.max(width, height)
  const shortSide = Math.min(width, height)
  var itemW = 0
  var itemH = 0
  let layout = []
  let index = 0

  if (landscape) {
    const margin = longSide / 8 * 0.1
    itemW = longSide / 8 / 32
    itemH = shortSide / 8 * 0.7
    for (let r = 0; r < 1; r++) {
      for (let c = 0; c < 16; c++) {
        index++
        let row
        row = {
          "i": "n" + index,
          "w": itemW,
          "h": itemH,
          "x": itemW * r + (margin * (c+1)/2) + margin*0.8,
          "y": itemH * r + margin,
          "component": "Fader",
          "content": {
            "channel": 1,
            "note": index,
            "value": 0,
            "direction": 'vertical',
            "bg": "#444444",
            "bgactive": "#999999"
          }
        }
        layout.push(row)
      }
    }
  }
  else if (portrait) {
    const margin = shortSide / 8 * 0.1
    itemW = shortSide / 8 * 0.8
    itemH = longSide / 8 / 20
    for (let r = 0; r < 16; r++) {
      for (let c = 0; c < 1; c++) {
        index++
        let row
        row = {
          "i": "n" + index,
          "w": itemW,
          "h": itemH,
          "x": itemW * c + margin,
          "y": itemH * r + margin * (r+1),
          "component": "Fader",
          "content": {
            "channel": 1,
            "note": index,
            "value": 0,
            "direction": 'horizontal',
            "bg": "#444444",
            "bgactive": "#999999"
          }
        }
        layout.push(row)
      }
    }
  }

  let data;
  data = {
    name: 'Manyfaders',
    author: '',
    bg: '#555555',
    layout: layout,
  }
  return data
}

export default manyfaders