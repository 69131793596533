import { ManyPads } from './ManyPads'
import { createFeature } from '../core'

export default createFeature({
  name: 'manypads',
  category: 'instruments',
  description:
    'manypads',
  instruments: [
    {
      id: 'manypads',
      sortKey: 'manypads',
      name: 'manypads',
      description:
        'manypads',
      component: ManyPads,
    },
  ],
})
