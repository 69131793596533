export function getFromLS(key) {
  let ls = {}
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem(key)) || {}
    } catch (e) {
      /* error */
    }
  }
  return ls[key]
}

export function saveToLS(key, value) {
  if (global.localStorage) {
    try {
      global.localStorage.setItem(
        key,
        JSON.stringify({
          [key]: value,
        })
      )
    } catch (e) {
      /* error */
    }
  }
}
