import React from 'react'
import { MIDI } from '../core'
import { Knob as RotaryKnob } from 'react-rotary-knob'
import * as skins from './skins'

export class Knob extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bg: this.props.bg || 'var(--c-instrument-bg)',
      bgactive: this.props.bgactive || 'var(--c-instrument-active)',
      channel: this.props.channel || 1,
      clampMax: this.props.clampMax || 315,
      clampMin: this.props.clampMin || 45,
      defaultValue: this.props.defaultValue || 0,
      direction: this.props.direction || 'vertical',
      lockHorizontal: this.props.lockHorizontal || false,
      lockVertical: this.props.lockVertical || true,
      max: this.props.max || 127,
      min: this.props.min || 0,
      newDate: new Date(),
      note: this.props.note || 1,
      preciseMode: this.props.preciseMode || true,
      rotateDegrees: this.props.rotateDegrees || 180,
      skin: skins.skin_default || null,
      text: this.props.text || '0',
      unlockDistance: this.props.unlockDistance || 0,
      padding: this.props.padding || '3em',
      value: this.props.value || 1,
    }
    this.onChange = this.onChange.bind(this)
  }

  onChange(value) {
    const maxDistance = 25
    let distance = Math.abs(value - this.state.value)
    if (distance > maxDistance) {
      return
    } else {
      this.setState({ value: value })
    }
    const controlChangeValue = Math.round(value)
    const channel = this.props.channel - 1
    const controlChangeCommand = parseInt('B0', 16)
    const controlChangeWithChannel = controlChangeCommand + channel
    const controlChangeHex = controlChangeWithChannel.toString(16)
    const controlChange = parseInt(controlChangeHex, 16)

    var newDate = new Date()
    var oldDate = this.state.newDate
    var timeDiff = (newDate - oldDate) / 1000
    if (timeDiff > 0.1) {
      this.setState({
        newDate: newDate,
      })
      return MIDI.send([controlChange, this.props.note, controlChangeValue])
    }
  }

  render() {
    let { value, ...rest } = this.props

    return (
      <RotaryKnob
        bg={this.state.bg}
        bgactive={this.state.bgactive}
        channel={this.state.channel}
        clampMax={this.state.clampMax}
        clampMin={this.state.clampMin}
        defaultValue={this.state.value}
        lockHorizontal={false}
        lockVertical={true}
        max={this.state.max}
        min={this.state.min}
        note={this.state.note}
        onChange={this.onChange}
        preciseMode={this.state.preciseMode}
        rotateDegrees={this.state.rotateDegrees}
        skin={this.state.skin}
        text={this.state.text}
        unlockDistance={this.state.unlockDistance}
        padding={this.state.padding}
        value={this.state.value}
        {...rest}
      />
    )
  }
}
