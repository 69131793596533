import { MIDI } from '../core'

import React from 'react'
import { TouchAbsorber } from '../core/midi/TouchAbsorber'

const buttons = [{ text: 'Name', note: 36 }]

export class Pad extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      text: this.props.text || '0',
      channel: this.props.channel || 0,
      note: this.props.note || 0,
      bg: this.props.bg || 'var(--c-instrument-bg)',
      bgactive: this.props.bgactive || 'var(--c-instrument-active)',
      value: this.props.value || 127,
      velocity: this.props.velocity || 'fixed',
    }
  }

  render() {
    return (
      <div
        onTouchStart={e => e.preventDefault()}
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <div
          style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        >
          {buttons.map((button, index) =>
            this.renderButton(
              button,
              index,
              this.props.text,
              this.props.channel,
              this.props.note,
              this.props.bg,
              this.props.bgactive,
              this.props.value,
              this.props.velocity
            )
          )}
        </div>
      </div>
    )
  }

  renderButton(button, index, text, note, bg, value, velocity) {
    const top = 0
    const left = 0
    const width = '100%'
    const height = '100%'
    return (
        <DrumButton
          background={this.props.bg}
          backgroundActive={this.props.bgactive}
          text={this.props.text}
          channel={this.props.channel}
          note={this.props.note}
          value={this.props.value}
          velocity={this.props.velocity}
          onTrigger={velocity => this.handleTrigger(note, velocity)}
          key={index}
          style={{
            position: 'absolute',
            top,
            left,
            width,
            height,
            // border: '1px solid rgba(0,0,0,0.5)',
          }}
        />
    )
  }

  handleTrigger(note, velocity) {
    var midiVelocity = ''
    if (this.props.velocity === 'fixed') {
      midiVelocity = Math.min(this.props.value, 127)
    }
    else if (this.props.velocity === 'radius') {
      midiVelocity = Math.max(0, Math.min(this.props.value, Math.round(velocity * 127)))
    }

    const channel = this.props.channel - 1
    const NoteOnCommand = parseInt(90, 16)
    const NoteOnWithChannel = NoteOnCommand + channel
    const NoteOnHex = NoteOnWithChannel.toString(16)
    const NoteOn = parseInt(NoteOnHex, 16)
    const NoteOffCommand = parseInt(80, 16)
    const NoteOffWithChannel = NoteOffCommand + channel
    const NoteOffHex = NoteOffWithChannel.toString(16)
    const NoteOff = parseInt(NoteOffHex, 16)
    
    MIDI.send([NoteOn, this.props.note, midiVelocity])
    MIDI.send([NoteOff, this.props.note, midiVelocity])
  }
}

class DrumButton extends React.PureComponent {
  constructor(props) {
    super(props)
    this.touchId = 0
    this.state = {
      text: this.props.text,
      channel: this.props.channel,
      note: this.props.note,
      bg: this.props.background,
      bgactive: this.props.backgroundActive,
      value: this.props.value,
      velocity: this.props.velocity
    }
  }
  render() {

    return (
      <TouchAbsorber>
        <div
          onTouchStart={this.handleTouchStart}
          onClick={this.handleClick}
          onTouchEnd={this.handleTouchEnd}
          onContextMenu={this.onContextMenu}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            transform: 'translateZ(0)',
            MozUserSelect: 'none',
            KhtmlUserSelect: 'none',
            WebkitUserSelect: 'none',
            userSelect: 'none',
          }}
          ref={this.registerTouchElement}
        >
          <div
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              background: this.props.background,
              transform: 'translateZ(0)',
            }}
          />
          <div
            ref={this.registerActiveElement}
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              background: this.props.backgroundActive,
              transform: 'translateZ(0)',
              transition: 'all 0.1s ease',
              opacity: 1
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transform: 'translateZ(0)',
              pointerEvents: 'none'
            }}
          >
            <div style={{
              pointerEvents: 'none',
              color: this.props.backgroundActive,
              textAlign: 'center',
              fontFamily: 'IBM Plex Mono', 
              // fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
              fontSize: '14px'}}>
              {this.props.text || this.props.note}
            </div>
          </div>
        </div>
      </TouchAbsorber>
    )
  }
  registerTouchElement = element => {
    this.element = element
  }
  registerActiveElement = element => {
    this.activeElement = element
    if (element) {
      element.style.opacity = '0'
    }
  }

  onContextMenu = e => {
    e.preventDefault()
  }

  handleTouchStart = e => {
    for (var i = 0; i < e.changedTouches.length; i++) {
      if (!this.element) return
      const touch = e.changedTouches[i]
      if (!touch) return 
      const rect = e.target.getBoundingClientRect()
      const x = (touch.clientX - rect.left) / rect.width
      const y = (touch.clientY - rect.top) / rect.height
      const velocity = 1 - 2 * (Math.pow(x - 0.5, 2) + Math.pow(y - 0.5, 2))
      this.props.onTrigger(velocity)
      if (!this.activeElement) return
      const touchId = ++this.touchId
      this.activeElement.style.opacity = velocity
      setTimeout(() => {
        if (touchId !== this.touchId) return
        if (!this.activeElement) return
        this.activeElement.style.opacity = '0'
      }, 100)
    }
  }

  handleClick = e => {
    if (!this.element) return
    const touch = e
    if (!touch) return
    const rect = this.element.getBoundingClientRect()
    const x = (touch.clientX - rect.left) / rect.width
    const y = (touch.clientY - rect.top) / rect.height
    const velocity = 1 - 2 * (Math.pow(x - 0.5, 2) + Math.pow(y - 0.5, 2))
    this.props.onTrigger(velocity)
    if (!this.activeElement) return
    const touchId = ++this.touchId
    this.activeElement.style.opacity = velocity
    setTimeout(() => {
      if (touchId !== this.touchId) return
      if (!this.activeElement) return
      this.activeElement.style.opacity = '0'
    }, 50)
  }
}


