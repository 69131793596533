import React from 'react'
import { Select, Divider } from 'antd'
import { getFromLS } from '../utils/LocalStorage'

class LayoutTemplates extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      templates: this.props.templates,
      saved: this.props.saved,
      data: this.props.data,
    }
    this.handleChange = this.handleChange.bind(this)
    this.checkSavedLayouts = this.checkSavedLayouts.bind(this)
  }

  handleChange(key) {
    this.props.onLoadLayoutTemplate(key)
  }

  checkSavedLayouts() {
    const hash = window.location.hash
    const appName = hash.replace(/^.{2}/g, '')
    const saved = getFromLS(appName + '-saved') || []
    const templates = getFromLS(appName + '-templates') || []
    // const templates = this.state.templates
    var found = false
    saved.map(template => {
      if (template.name === this.state.data.name) {
        found = true
      }
    })
    if (!found) {
      templates.map(template => {
        if (template.name === this.state.data.name) {
          found = true
        }
      })
    }
    return found
  }

  render() {
    const hash = window.location.hash
    const appName =  hash.replace(/^.{2}/g, '')
    const templates = getFromLS(appName + '-templates') || this.state.templates || []
    return (
      <Select
        showArrow
        style={{
          width: '100%',
          background: 'var(--c-btn-bg)',
          color: 'var(--c-btn)',
        }}
        defaultValue={
          this.state.data.name === 'Demo'
            ? 'Select layout'
            : this.checkSavedLayouts()
              ? this.state.data.name
              : 'Select layout'
        }
        placeholder={'Select layout'}
        onSelect={this.handleChange}
        listHeight={'auto'}
        allowClear={false}
        showSearch={false}
      >
        {/* <Select.OptGroup label="Factory"> */}
        {templates.map(template => (
          <Select.Option
            key={'template-' + template.name}
            value={'template-' + template.name}
          >
            {template.name}
          </Select.Option>
        ))}
        {/* </Select.OptGroup> */}

        {this.state.saved.length > 0 ? (
          <Divider
            style={{
              background: 'rgba(255,255,255,0.1)',
              margin: '8px 0',
              height: '1px',
              minHeight: '0px',
              padding: '0',
              pointerEvents: 'none',
            }}
          />
        ) : null}

        {/* {this.state.saved.length > 0 ? */}
        {/* <Select.OptGroup label="User"> */}
        {this.state.saved.map(saved => (
          <Select.Option key={'saved-' + saved.name} value={'saved-' + saved.name}>
            {saved.name}
          </Select.Option>
        ))}
        {/* </Select.OptGroup> */}
        {/* : null } */}
      </Select>
    )
  }
}

export default LayoutTemplates
