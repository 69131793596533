export const manytoggles = () => {
  const width = document.documentElement.clientWidth
  const height = document.documentElement.clientHeight
  const portrait = width < height
  const landscape = height < width
  let layout = []
  let index = 0
  var itemW
  var itemH


  if (landscape) {
    itemW = width / 8 / 8 - 0.2
    itemH = height / 8 / 8 - 0.2
    const margin = itemW / 20 - 0.4
    for (let i = 0; i < 8; i++) {
      for (let j = 0; j < 8; j++) {
        index++
        let row
        row = {
          "i": "n" + index,
          "w": itemW - margin,
          "h": itemH - margin,
          "x": itemW * j + margin * j/2,
          "y": itemH * i + margin * i/2,
          "component": "Toggle",
          "content": {
            "text": index,
            "channel": 1,
            "note": index,
            "value": 127,
            "velocity": "fixed",
            "bg": index%2 === 0 ? "#444444" : "#555555",
            "bgactive": "#cccccc"
          }
        }
        layout.push(row)
      }
    }
  }
  if (portrait) {
    itemW = width / 8 / 8
    itemH = height / 8 / 8
    const margin = 0
    for (let i = 0; i < 8; i++) {
      for (let j = 0; j < 8; j++) {
        index++
        let row
        row = {
          "i": "n" + index,
          "w": itemW,
          "h": itemH,
          "x": itemW * j + margin * j,
          "y": itemH * i + margin * i,
          "component": "Toggle",
          "content": {
            "text": index,
            "channel": 1,
            "note": index,
            "value": 127,
            "velocity": "fixed",
            "bg": index%2 === 0 ? "#444444" : "#555555",
            "bgactive": "#cccccc"
          }
        }
        layout.push(row)
      }
    }
  }
  

  let data = {
    name: 'Manytoggles',
    author: '',
    bg: '#222222',
    layout: layout,
  }
  return data
}

export default manytoggles