import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'react-emotion'
import { Button, Space } from 'antd'
import GridLayout from 'react-grid-layout'
import ReactResizeDetector from 'react-resize-detector'
import './layout.css'
import '../../node_modules/react-grid-layout/css/styles.css'
import '../../node_modules/react-resizable/css/styles.css'
import LayoutMenu from './LayoutMenu'
import LayoutSettings from './LayoutSettings'
import Instrument from '../instrument/Instrument'
import InstrumentSettings from '../instrument/InstrumentSettings'
import 'firebase/analytics'
// import ErrorBoundary from '../utils/ErrorBoundary'
import { logEvent } from '../utils/Firebase'
import { saveToLS } from '../utils/LocalStorage'

export default class Layout extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      appName: this.props.appName,
      data: this.props.data,
      templates: this.props.templates,
      saved: this.props.saved,
      hash: window.location.hash,
      clientWidth: document.documentElement.clientWidth,
      unlocked: false,
      itemCounter: this.props.data.layout.length || 0,
      //TODO: Refactor index: itemCounter should be updated after add, duplicate & remove
    }
    this.onAddItem = this.onAddItem.bind(this)
    this.onDuplicateItem = this.onDuplicateItem.bind(this)
    this.handleDragItem = this.handleDragItem.bind(this)
    this.handleEditLayout = this.handleEditLayout.bind(this)
    this.layout = React.createRef();
  }

  onRemoveItem(i) {
    logEvent('item-remove', {item_type:this.state.data.layout[i].component})
    //TODO: itemCounter should be recalculated when removing an item…
    const updatedLayout = {
      layout: this.state.data.layout
        .slice(0, i)
        .concat(
          this.state.data.layout.slice(i + 1, this.state.data.layout.length)
        ),
    }
    return (
      this.setState({
        data: {
          ...updatedLayout,
          bg: this.state.data.bg,
          author: this.state.data.author,
          name: this.state.data.name,
          width: this.state.data.width,
          height: this.state.data.height,
        },
      }),
      this.saveCurrentLayout()
    )
  }

  onAddItem(type) {
    const allYPos = this.state.data.layout.map(item => { 
      var yPos = []
      yPos.push(item.y + item.h)
      return yPos
    })
    const columns = this.state.clientWidth / 8
    const index = this.state.itemCounter - 1
    const item = this.state.data.layout[index] || {
      x: 0,
      w: 0,
      y: 0,
      h: 0,
      content: {
        note: 35,
        channel: 1,
        text: null
      }
    }
    const newPosX = item.x + item.w < columns - item.w + 1
        ? item.x + item.w
        : 0
    const newPosY = item.x + item.w < columns - item.w + 1
        ? item.y
        : item.y + item.h
    const newText = item.content.text === null
        ? item.content.note + 1
        : item.content.note + 1
    const newNote = item.content.note === 0
        ? 36
        : item.content.note + 1

    const updatedLayout = {
      layout: this.state.data.layout.concat({
        i: 'n' + index + 1,
        w: type === 'knob'
        ? this.state.clientWidth / 8 / 5
        : type === 'pad'
          ? this.state.clientWidth / 8 / 4
          : type === 'fader'
            ? this.state.clientWidth / 8 / 20
            : type === 'toggle'
              ? this.state.clientWidth / 8 / 4
              : type === 'text'
                ? this.state.clientWidth / 8 / 3
                : this.state.clientWidth / 8,
        h: type === 'knob'
        ? this.state.clientWidth / 8 / 5
        : type === 'pad'
          ? this.state.clientWidth / 8 / 4
          : type === 'fader'
            ? this.state.clientWidth / 8 / 3
            : type === 'toggle'
              ? this.state.clientWidth / 8 / 6
              : type === 'text'
                ? this.state.clientWidth / 8 / 8
                : this.state.clientWidth / 8,
        x: newPosX || 0,
        y: newPosY || Math.max(Math.max(...allYPos), 0),
        minW: 1,
        minH: 1,
        moved: false,
        static: !this.state.unlocked,
        component:
          type === 'knob'
            ? 'Knob'
            : type === 'pad'
              ? 'Pad'
              : type === 'fader'
                ? 'Fader'
                : type === 'toggle'
                  ? 'Toggle'
                  : type === 'text'
                    ? 'Text'
                    : 'Text',
        content: {
          text: type === 'knob'
          ? 'Knob'
          : type === 'pad'
            ? newText
            : type === 'fader'
              ? 'Fader'
              : type === 'toggle'
                ? 'Toggle'
                : type === 'text'
                  ? 'Text'
                  : 'Text',
          channel: item.channel || 1,
          note: newNote || 1,
          value:
            type === 'knob'
              ? 0
              : type === 'pad'
                ? 127
                : type === 'fader'
                  ? 0
                  : type === 'toggle'
                    ? 127
                    : type === 'text'
                      ? null
                      : null,
          min: 1,
          max: 127,
          bg: 
            type === 'knob'
                ? '#000000'
                : type === 'pad'
                  ? '#000000'
                  : type === 'fader'
                    ? '#000000'
                    : type === 'toggle'
                      ? '#000000'
                      : type === 'text'
                        ? 'transparent'
                        : '#000000',
          bgactive: 
            type === 'knob'
                ? '#ffffff'
                : type === 'pad'
                  ? '#999999'
                  : type === 'fader'
                    ? '#333333'
                    : type === 'toggle'
                      ? '#ffffff'
                      : type === 'text'
                        ? '#ffffff'
                        : '#ffffff',
          direction: 'vertical',
          velocity: 'fixed',
        },
      }),
    }

    // Log Firebase event
    logEvent('item-add', {
      item_type: type,
    })

    setTimeout(() => {
      const layoutElement = ReactDOM.findDOMNode(this.layout.current);
      layoutElement.scrollTop = layoutElement.scrollHeight;
    }, 200);

    return (
      this.setState({
        data: {
          ...updatedLayout,
          bg: this.state.data.bg,
          author: this.state.data.author,
          name: this.state.data.name,
          width: this.state.data.width,
          height: this.state.data.height,
        },
        itemCounter: this.state.itemCounter + 1,
      }),
      this.saveCurrentLayout()
    )
  }

  onDuplicateItem(i) {
    // const allYPos = this.state.data.layout.map(item => { 
    //   var yPos = []
    //   yPos.push(item.y + item.h)
    //   return yPos
    // })
    const columns = this.state.clientWidth / 8
    const index = this.state.itemCounter - 1
    const item = this.state.data.layout[index] || {
      x: 0,
      w: 0,
      y: 0,
      h: 0,
      content: {
        note: 35,
        channel: 1,
        text: null
      }
    }
    const newPosX = item.x + item.w < columns - item.w + 1
        ? item.x + item.w
        : 0
    const newPosY = item.x + item.w < columns - item.w + 1
        ? item.y
        : item.y + item.h
    // const newText = item.content.text === null
    //     ? item.content.note + 1
    //     : item.content.note + 1
    const newNote = item.content.note === 0
        ? 36
        : item.content.note

    const updatedLayout = {
      layout: this.state.data.layout.concat({
        i: 'n' + index + 1,
        w: this.state.data.layout[i].w,
        h: this.state.data.layout[i].h,
        x: newPosX,
        y: newPosY,
        minW: this.state.data.layout[i].minW,
        minH: this.state.data.layout[i].minH,
        moved: false,
        static: !this.state.unlocked,
        component: this.state.data.layout[i].component,
        content: {
          text: this.state.data.layout[i].content.text,
          channel: this.state.data.layout[i].content.channel,
          note: newNote,
          value: this.state.data.layout[i].content.value,
          min: this.state.data.layout[i].content.min,
          max: this.state.data.layout[i].content.max,
          bg: this.state.data.layout[i].content.bg,
          bgactive: this.state.data.layout[i].content.bgactive,
        },
      }),
    }

    // Log Firebase event
    logEvent('item-duplicate', {
      item_type: this.state.data.layout[i].component,
    })

    return (
      this.setState({
        data: {
          ...updatedLayout,
          bg: this.state.data.bg,
          author: this.state.data.author,
          name: this.state.data.name,
          width: this.state.data.width,
          height: this.state.data.height,
        },
        itemCounter: this.state.itemCounter + 1,
      }),
      this.saveCurrentLayout()
    )
  }

  handleDragItem(currentLayout) {
    const newLayout = this.state.data.layout.map(item => {
      return item
    })
    const mergeArrayObjects = (arr1, arr2) => {
      return arr1.map((item, i) => {
        if (item.id === arr2[i].id) {
          return Object.assign({}, item, arr2[i])
        }
      })
    }
    const mergedLayouts = mergeArrayObjects(newLayout, currentLayout)
    return (
      this.setState({
        data: {
          layout: mergedLayouts,
          bg: this.state.data.bg,
          author: this.state.data.author,
          name: this.state.data.name,
          width: this.state.data.width,
          height: this.state.data.height,
          cols: this.state.data.cols,
          colors: this.state.data.colors
        },
      }),
      this.saveCurrentLayout()
    )
  }

  toggleEditMode() {
    let newLayout = JSON.parse(JSON.stringify(this.state.data)).layout.map(
      item => {
        item['static'] = this.state.unlocked
        return item
      }
    )
    const updatedLayout = { layout: newLayout }

    // Log Firebase event
    // logEvent('layout-unlock')

    return (
      this.setState({
        data: {
          ...updatedLayout,
          bg: this.state.data.bg,
          author: this.state.data.author,
          name: this.state.data.name,
          width: this.state.data.width,
          height: this.state.data.height,
          cols: this.state.data.cols,
          colors: this.state.data.colors
        },
        unlocked: !this.state.unlocked,
      }),
      this.saveCurrentLayout()
    )
  }

  componentDidMount() {
    let justComponents = this.state.data.layout.map(item => {
      return item.component
    })
    let newLayout = JSON.parse(JSON.stringify(this.state.data)).layout.map(
      item => {
        item['static'] = true
        return item
      }
    )
    const updatedLayout = { layout: newLayout }

    return this.setState({
      data: {
        ...updatedLayout,
        bg: this.state.data.bg,
        author: this.state.data.author,
        name: this.state.data.name,
        width: this.state.data.width,
        height: this.state.data.height,
        cols: this.state.data.cols,
        colors: this.state.data.colors
      },
      components: justComponents,
      // cols: { layout: columns}
    })
  }

  saveCurrentLayout() {
    saveToLS(this.state.appName + '-currentLayout', this.state.data)
    this.forceUpdate()
  }

  convertStringToComponent(str, i) {
    let thisComp = (str => {
      // console.log(str, i);
      return (
        <Instrument
          bg={this.state.data.layout[i].content.bg}
          bgactive={this.state.data.layout[i].content.bgactive}
          channel={this.state.data.layout[i].content.channel}
          component={str}
          defaultValue={this.state.data.layout[i].content.defaultValue}
          direction={this.state.data.layout[i].content.direction}
          i={i}
          key={'item' + i}
          note={this.state.data.layout[i].content.note}
          store={this.props.store}
          text={this.state.data.layout[i].content.text}
          value={this.state.data.layout[i].content.value}
          velocity={this.state.data.layout[i].content.velocity}
          x={this.state.data.layout[i].x}
          y={this.state.data.layout[i].y}
          w={this.state.data.layout[i].w}
          h={this.state.data.layout[i].h}
        />
      )
    })(str)
    return thisComp
  }

  handleEditLayout(event) {
    // Log Firebase event
    // logEvent('layout-edit')

    const data = this.state.data
    data[event.target.name] = event.target.value
    this.setState({
      data: data,
    })
    this.saveCurrentLayout()
    setTimeout(() => {
      this.handleDragItem(this.state.data.layout)
    }, 100);
  }

  handleEditItem(event, index) {
    // logEvent('item-edit', {item_type:this.state.data.layout[index].component})

    const data = this.state.data
    if (data.layout[index][event.target.name]) {
      data.layout[index][event.target.name] = event.target.value
    } else {
      data.layout[index].content[event.target.name] = event.target.value
    }

    this.setState({
      data: data,
    })
    this.saveCurrentLayout()
    setTimeout(() => {
      this.handleDragItem(this.state.data.layout)
    }, 100);
  }

  render() {
    let items = this.state.data.layout.map((item, i) => {
      let component = this.convertStringToComponent(item.component, i)
      return (
        <Item key={item.i} id={`item-${item.i}`} comp={item.component}>
          {this.state.unlocked ?(
            <EditOverlay visible={this.state.unlocked}>
              <InstrumentSettings
                data={this.state.data}
                instrument={this.state.data.layout[i]}
                bg={item.content.bg}
                bgactive={item.content.bgactive}
                channel={item.content.channel}
                component={item.component}
                direction={item.content.direction}
                iconColor="black"
                index={i}
                max={item.content.max}
                min={item.content.min}
                note={item.content.note}
                handleEditItem={e => this.handleEditItem(e, i)}
                text={item.content.text}
                value={item.content.value}
                velocity={item.content.velocity}
              >
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Button
                    type="default"
                    onClick={this.onDuplicateItem.bind(this, i)}
                    block
                  >
                    Duplicate
                  </Button>
                  <Button
                    type="danger"
                    block
                    onClick={this.onRemoveItem.bind(this, i)}
                  >
                    Delete
                  </Button>
                </Space>
              </InstrumentSettings>
            </EditOverlay>
          ) : null}
          {component}
        </Item>
      )
    })

    return (
      <div>
        <LayoutMenu
          data={this.state.data}
          unlocked={this.state.unlocked}
          onAddItem={item => this.onAddItem(item)}
          toggleEditMode={() => this.toggleEditMode()}
        >
          <LayoutSettings
            data={this.state.data}
            templates={this.state.templates}
            saved={this.state.saved}
            unlocked={this.state.unlocked}
            handleEditLayout={e => this.handleEditLayout(e)}
          />
        </LayoutMenu>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={width => this.setState({ clientWidth: width })}
          render={({ width, height }) => (
          <StyledLayout
            ref={this.layout}
            width={this.state.clientWidth}
            style={{
              background: this.state.data.bg || '#222',
              width: this.state.data.width || '100vw',
              height: this.state.data.height || '100vh',
              overflow: 'auto'
            }}
            layout={this.state.data.layout}
            cols={parseInt(this.state.data.cols) || this.state.clientWidth / 8}
            static={!this.state.unlocked}
            rowHeight={8}
            margin={[0, 0]}
            containerPadding={[0, 0]}
            preventCollision={true}
            compactType={null}
            onResizeStop={this.handleDragItem}
            onDragStop={this.handleDragItem}
            // handleEditLayout={this.handleLayoutChange}
            // isDroppable={true}
            // onDrop={e => console.log('onDrop-> ', e)}
          >
            {items}
        </StyledLayout>
          )}
          />
      </div>
    )
  }
}


const StyledLayout = styled(GridLayout)`
  .react-resizable-handle {
    background: none;
  }
  .react-resizable-handle.react-resizable-handle-se:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 5px 5px;
    border-color: transparent transparent rgba(255, 255, 255, 0.3) transparent;
    z-index: 1;
    opacity: 1;
    position: absolute;
    right: 2px;
    bottom: 2px;
    pointer-events: none;
    mix-blend-mode: difference;
    background: none;
  }
`

const Item = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const EditOverlay = styled('div')`
  border: 1px dotted rgba(255, 255, 255, 0.5);
  color: white;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  pointer-events: none;
  position: absolute;
  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    top: 0px;
    left: 0px;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: -1;
    pointer-events: ${props => (props.visible ? 'all' : 'none')};
  }
  > div > button {
    transform: ${props => (props.visible ? 'scale(1) translate(-30%,-30%)' : 'scale(0) translate(-30%,-30%)')};
    transition: all 0.5s ease;
    transition-delay: 1s;
  }
  ~.react-resizable-handle.react-resizable-handle-se {
    transform: scale(2);
    background: none;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDx0aXRsZT5pbWFnZTwvdGl0bGU+CiAgICA8ZyBpZD0iSWNvbnNTdmciIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIG9wYWNpdHk9IjAuNzk1NzkxODUzIj4KICAgICAgICA8ZyBpZD0iLSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUwNC4wMDAwMDAsIC01NjUuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9ImltYWdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1MDQuMDAwMDAwLCA1NjUuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iR3JvdXAiPgogICAgICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBwb2ludHM9IjAuMiA3LjIgNy4yIDcuMiA3LjIgMC4yIj48L3BvbHlnb24+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-position: right 35% bottom 35%;
    mix-blend-mode: difference;
    :after {
      display: none;
    }
  }
`
