import React from 'react'
import styled from 'react-emotion'
import Draggable from 'react-draggable';
import { Popover, Button } from 'antd'
import {
  KnobIcon,
  ToggleIcon,
  FaderIcon,
  PadIcon,
  TextIcon,
  LockedIcon,
  UnlockedIcon,
  PlusIcon,
} from './LayoutIcons'

export default class LayoutMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hash: window.location.hash,
      data: this.props.data,
      showMenu: false,
      // menuPosition: {x:0, y:0},
      unlocked: this.props.unlocked
    }
  }

  handleShowMenu = showMenu => {
    this.setState({ 
      showMenu: !this.state.showMenu,
      // menuPosition: {x:0, y:0},
    })
  }


  handleUnlock = unlocked => {
    this.props.toggleEditMode()
    if (this.state.showMenu && this.state.unlocked) {
      this.handleShowMenu()
    }
    this.setState(prevstate => ({ unlocked: !prevstate.unlocked}));
  }

  handleAddItem = type => {
    this.props.onAddItem(type)
    if (!this.state.unlocked) {
      setTimeout(() => {
        this.handleUnlock()
      }, 100);
    }
    setTimeout(() => {
      this.handleShowMenu()
    }, 300);
    // if (this.state.unlocked) {
    //   setTimeout(() => {
    //     this.handleShowMenu()
    //   }, 0);
    // }
  }

  // handleStop = position => {
  //   this.setState({ menuPosition: {x: -document.documentElement.clientWidth+position.x , y: -document.documentElement.clientHeight+position.y}})
  // }

  render() {
    // const menuPosition = this.state.menuPosition
    return ( 
      <Wrapper>
        <TopLeftMenu unlocked={this.state.unlocked} onClick={() => this.handleUnlock()}>
          {this.state.unlocked ? (
            <div>
                <UnlockedIcon style={{ fontSize: '1.5em',color: '#ffffff'}} />
            </div>
          ) : (
            <div style={{filter: 'grayscale(100%)'}}>
              <div style={{mixBlendMode: 'difference', position: 'absolute'}}>
                  <LockedIcon style={{ mixBlendMode: 'difference', fontSize: '1.5em' }} />
              </div>
              <div>
                  <LockedIcon style={{ fontSize: '1.5em',color: this.props.data.bg || '#000000'}} />
              </div>
            </div>
          )}
        </TopLeftMenu>
        <TopCenterMenu>
          <StyledPopover
            hash={this.state.hash}
            placement="bottom"
            title=""
            trigger="click"
            showMenu={this.state.showMenu}
            handleVisibleChange
            align={{ offset: [0, -10] }}
            content={
              <Draggable
                handle=".handle"
                axis="both"
                bounds={{
                  left: -document.documentElement.clientWidth+50,
                  top: -document.documentElement.clientHeight+310+50,
                  right: 0,
                  bottom: 50,
                }}
                defaultPosition={this.state.menuPosition}
                // onStop={(item) => this.handleStop(item)}
                // onStop={(item) => this.setState({ menuPosition: {x: -document.documentElement.clientWidth+item.x+50/2 , y: -document.documentElement.clientHeight+item.y+310/2+50}})}
              >
                <div>
                <PopoverContent showMenu={this.state.showMenu}>
                  {/* <div className="handle">
                    <HandleIcon style={{fontSize: '1.3em', color: 'rgba(255,255,255,0.3)'}}/>
                  </div> */}
                  <Button
                    style={{color: 'var(--c-btn)', border: 'none' }}
                    shape="circle"
                    size="large"
                    icon={<TextIcon style={{fontSize: '1.5em'}} />}
                    onClick={() => this.handleAddItem('text')}
                  />
                  {this.state.hash === '#/manyknobs' ||
                  this.state.hash === '#/allthemidi' ? (
                    <Button
                      style={{color: 'var(--c-btn)', border: 'none',
                      }}
                      shape="circle"
                      size="large"
                      icon={<KnobIcon style={{fontSize: '1.3em'}} />}
                      onClick={() => this.handleAddItem('knob')}
                    />
                  ) : null}

                  {this.state.hash === '#/manytoggles' ||
                  this.state.hash === '#/allthemidi' ? (
                    <Button
                      style={{color: 'var(--c-btn)', border: 'none',
                      }}
                      shape="circle"
                      size="large"
                      icon={<ToggleIcon style={{fontSize: '1.3em'}} />}
                      onClick={() => this.handleAddItem('toggle')}
                    />
                  ) : null}

                  {this.state.hash === '#/manyfaders' ||
                  this.state.hash === '#/allthemidi' ? (
                    <Button
                      style={{color: 'var(--c-btn)', border: 'none',
                      }}
                      shape="circle"
                      size="large"
                      icon={<FaderIcon style={{fontSize: '1.3em'}} />}
                      onClick={() => this.handleAddItem('fader')}
                    />
                  ) : null}

                  {this.state.hash === '#/manypads' ||
                  this.state.hash === '#/allthemidi' ? (
                    <Button
                      style={{color: 'var(--c-btn)', border: 'none',
                      }}
                      shape="circle"
                      size="large"
                      icon={<PadIcon style={{fontSize: '1.3em'}} />}
                      onClick={() => this.handleAddItem('pad')}
                    />
                  ) : null}
                </PopoverContent>
                </div>
                </Draggable>
            }
          >
            <PopoverButton showMenu={this.state.showMenu} onClick={() => this.handleShowMenu()}>
            {this.state.unlocked ? (
              <PlusIcon style={{ fontSize: '1em',color: 'rgba(255,255,255,1)'}} />
            ) : (
              <div style={{filter: 'grayscale(100%)'}}>
                <div style={{mixBlendMode: 'difference', position: 'absolute'}}>
                    <PlusIcon style={{ mixBlendMode: 'difference', fontSize: '1em' }} />
                </div>
                <div>
                    <PlusIcon style={{ fontSize: '1em',color: this.props.data.bg || '#000000'}} />
                </div>
              </div>
            )}
              
            </PopoverButton>
          </StyledPopover>
        </TopCenterMenu>
        <TopRightMenu>
            {this.props.children}
            {/* <div onClick={() => this.handleUnlock()}>
            {this.state.unlocked ? (
              <UnlockedIcon style={{ fontSize: '1.2em',color: '#ffffff'}} />
            ) : (
              <LockedIcon style={{ fontSize: '1.2em',color: '#ffffff'}} />
            )}
          </div> */}
        </TopRightMenu>
        <BottomLeftMenu />
        {/* <BottomRightMenu>
          <StyledPopover
            hash={this.state.hash}
            placement="top"
            title=""
            trigger="click"
            showMenu={this.state.showMenu}
            visible={this.state.showMenu}
            content={
              <Draggable
                handle=".handle"
                axis="both"
                bounds={{
                  left: -document.documentElement.clientWidth+50,
                  top: -document.documentElement.clientHeight+310+50,
                  right: 0,
                  bottom: 50,
                }}
                defaultPosition={this.state.menuPosition}
                // onStop={(item) => this.handleStop(item)}
                // onStop={(item) => this.setState({ menuPosition: {x: -document.documentElement.clientWidth+item.x+50/2 , y: -document.documentElement.clientHeight+item.y+310/2+50}})}
              >
                <div>
                <PopoverContent showMenu={this.state.showMenu}>
                  <div className="handle">
                    <HandleIcon style={{fontSize: '1.3em', color: 'rgba(255,255,255,0.3)'}}/>
                  </div>
                  <Button
                    style={{color: 'var(--c-btn)', border: 'none', fontSize: '1.3em' }}
                    shape="circle"
                    size="large"
                    icon={<TextIcon style={{fontSize: '1.3em'}} />}
                    onClick={() => this.handleAddItem('text')}
                  />
                  {this.state.hash === '#/manyknobs' ||
                  this.state.hash === '#/allthemidi' ? (
                    <Button
                      style={{color: 'var(--c-btn)', border: 'none', fontSize: '1.3em',
                      }}
                      shape="circle"
                      size="large"
                      icon={<KnobIcon style={{fontSize: '1.3em'}} />}
                      onClick={() => this.handleAddItem('knob')}
                    />
                  ) : null}

                  {this.state.hash === '#/manytoggles' ||
                  this.state.hash === '#/allthemidi' ? (
                    <Button
                      style={{color: 'var(--c-btn)', border: 'none', fontSize: '1.3em',
                      }}
                      shape="circle"
                      size="large"
                      icon={<ToggleIcon style={{fontSize: '1.3em'}} />}
                      onClick={() => this.handleAddItem('toggle')}
                    />
                  ) : null}

                  {this.state.hash === '#/manyfaders' ||
                  this.state.hash === '#/allthemidi' ? (
                    <Button
                      style={{color: 'var(--c-btn)', border: 'none', fontSize: '1.3em',
                      }}
                      shape="circle"
                      size="large"
                      icon={<FaderIcon style={{fontSize: '1.3em'}} />}
                      onClick={() => this.handleAddItem('fader')}
                    />
                  ) : null}

                  {this.state.hash === '#/manypads' ||
                  this.state.hash === '#/allthemidi' ? (
                    <Button
                      style={{color: 'var(--c-btn)', border: 'none', fontSize: '1.3em',
                      }}
                      shape="circle"
                      size="large"
                      icon={<PadIcon style={{fontSize: '1.3em'}} />}
                      onClick={() => this.handleAddItem('pad')}
                    />
                  ) : null}
                </PopoverContent>
                </div>
                </Draggable>
            }
          >
            <PopoverButton showMenu={this.state.showMenu} onClick={() => this.handleShowMenu()}>
              <PlusIcon style={{ fontSize: '1.2em'}} />
            </PopoverButton>
          </StyledPopover>
        </BottomRightMenu> */}
        <TopBarBackground unlocked={this.state.unlocked}/>
      </Wrapper>
    )
  }
}


const Wrapper = styled('div')`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 9;
  pointer-events: none;
  > * {
    pointer-events: auto;
    cursor: pointer;
  }
  /* iPhone X and Xs Max */
  @media only screen 
      and (device-width: 375px) 
      and (device-height: 812px) 
      and (-webkit-device-pixel-ratio: 3)
      and (orientation : landscape) { 
        width: calc(100% - 90px);
      }
  }
  /* iPhone XR */
  @media only screen 
      and (device-width: 414px) 
      and (device-height: 896px) 
      and (-webkit-device-pixel-ratio: 2) 
      and (orientation : landscape) { 
        width: calc(100% - 90px);
      }
  }
  /* iPhone 11 – 1792x828px at 326ppi */
  @media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) 
    and (orientation : landscape) { 
      width: calc(100% - 90px);
    }
  /* iPhone 11 Pro – 2436x1125px at 458ppi */
  @media only screen 
      and (device-width: 375px) 
      and (device-height: 812px) 
      and (-webkit-device-pixel-ratio: 3)
      and (orientation : landscape) { 
        width: calc(100% - 90px);
      }
  /* iPhone 11 Pro Max – 2688x1242px at 458ppi */
  @media only screen 
      and (device-width: 414px) 
      and (device-height: 896px) 
      and (-webkit-device-pixel-ratio: 3) 
      and (orientation : landscape) { 
        width: calc(100% - 90px);
      }
  }
`

const TopLeftMenu = styled('div')`
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    opacity: 0.5
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait)
  {
    left: 8px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:landscape)
  {
    left: 8px;
  }
  ${'' /* &:after {
    content: '';
    position: absolute;
    background: ${props => props.unlocked ? '#FF4136' : 'transparent'};
    z-index: -1;
    width: 200%;
    height: 200%;
    transform: rotate(45deg) translate(-85%, 0%);
  } */}
  > div {
    padding: 6px 3px;
  }
`

const TopCenterMenu = styled('div')`
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    opacity: 0.5
  }
  /* iPhone X and Xs Max */
  @media only screen 
      and (device-width: 375px) 
      and (device-height: 812px) 
      and (-webkit-device-pixel-ratio: 3)
      and (orientation : portrait) { 
        margin-top: 40px;
      }
  }
  /* iPhone XR */
  @media only screen 
      and (device-width: 414px) 
      and (device-height: 896px) 
      and (-webkit-device-pixel-ratio: 2) 
      and (orientation : portrait) { 
        margin-top: 40px;
      }
  }
  /* iPhone 11 – 1792x828px at 326ppi */
  @media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) 
    and (orientation : portrait) { 
        margin-top: 40px;
      }
  /* iPhone 11 Pro – 2436x1125px at 458ppi */
  @media only screen 
      and (device-width: 375px) 
      and (device-height: 812px) 
      and (-webkit-device-pixel-ratio: 3)
      and (orientation : portrait) { 
        margin-top: 40px;
      }
  /* iPhone 11 Pro Max – 2688x1242px at 458ppi */
  @media only screen 
      and (device-width: 414px) 
      and (device-height: 896px) 
      and (-webkit-device-pixel-ratio: 3) 
      and (orientation : portrait) { 
        margin-top: 40px;
      }
  }
`

const TopRightMenu = styled('div')`
  width: 50px;
  height: auto;
  min-height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait)
  {
    right: 8px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:landscape)
  {
    right: 8px;
  }
`

const BottomLeftMenu = styled('div')`
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center
`

// const BottomRightMenu = styled('div')`
//   width: 50px;
//   height: auto;
//   min-height: 50px;
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   z-index: 9;
//   display: flex;
//   align-items: flex-end;
//   justify-content: flex-end;
//   &:after {
//     content: '';
//     position: absolute;
//     background: black;
//     z-index: -1;
//     width: 200%;
//     height: 200%;
//     transform: rotate(45deg) translate(85%, 0%);
//   }
// `

const MyPopover = ({ className, ...props }) => <Popover {...props} overlayClassName={className}/>

const StyledPopover = styled(MyPopover)`
  z-index: 9;
  .ant-popover-arrow {
    border-top-color: #000000 !important;
    border-left-color: #000000 !important;
  }
`

const PopoverContent = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: #000000;
  border-radius: 30px 30px 30px 30px;
  height: 50px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px;
    padding: 0 !important;
  }
`

const PopoverButton = styled('div')`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    transform: ${props => props.showMenu ? 'rotate(45deg)' : "rotate(-90)"};
    opacity: ${props => props.showMenu ? '0.5' : '1'};
    transition: all 0.2s ease;
  }
`

const TopBarBackground = styled('div')`
  background: rgba(0,0,0,0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  transform: ${props => props.unlocked ? 'translate(0, 0px)' : 'translate(0, -50px)'};
  transition: all 0.2s ease;
  transition-delay: all 0.2s;
  z-index: -1;
`

