export default {
  knobX: 71,
  knobY: 71,
  svg: `
<svg width="208px" height="209px" viewBox="0 0 208 209" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 61.2 (89653) - https://sketch.com -->
    <title>image copy 15</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <linearGradient x1="50%" y1="50%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="#444040" stop-opacity="0.51098279" offset="0%"></stop>
            <stop stop-color="#131111" stop-opacity="0.893200861" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
            <stop stop-color="#FFFFFF" stop-opacity="0.5" offset="0%"></stop>
            <stop stop-color="#000000" stop-opacity="0.5" offset="100%"></stop>
        </linearGradient>
        <path d="M104,104 L52,208 L0,208 L0,0 L208,0 L208,208 L156,208 L104,104 Z" id="path-3"></path>
        <mask id="mask-4" fill="white">
            <use xlink:href="#path-3"></use>
        </mask>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="image-copy-15" transform="translate(0.000000, 1.000000)">
            <g id="SVG">
                <g id="Oval-2" transform="translate(4.000000, 4.000000)" fill="#FFFFFF" opacity="0">
                    <g id="66154cdd-78cb-4a52-5eca-286f54bab3d0-link" fill-rule="nonzero">
                        <circle id="66154cdd-78cb-4a52-5eca-286f54bab3d0" cx="100" cy="100" r="100"></circle>
                    </g>
                    <g id="66154cdd-78cb-4a52-5eca-286f54bab3d0-link">
                        <circle id="66154cdd-78cb-4a52-5eca-286f54bab3d0" cx="100" cy="100" r="100"></circle>
                    </g>
                </g>
                <g id="Oval-2" transform="translate(14.000000, 14.000000)" fill="#000000">
                    <g id="a6738d07-2d04-9cdc-b9c8-92fdc3bad5ce-link" transform="translate(4.000000, 4.000000)" fill-rule="nonzero">
                        <circle id="a6738d07-2d04-9cdc-b9c8-92fdc3bad5ce" cx="86" cy="86" r="86"></circle>
                    </g>
                    <g id="a6738d07-2d04-9cdc-b9c8-92fdc3bad5ce-link" transform="translate(4.000000, 4.000000)">
                        <circle id="a6738d07-2d04-9cdc-b9c8-92fdc3bad5ce" cx="86" cy="86" r="86"></circle>
                    </g>
                    <g id="a6738d07-2d04-9cdc-b9c8-92fdc3bad5ce-link">
                        <circle id="a6738d07-2d04-9cdc-b9c8-92fdc3bad5ce" cx="90" cy="90" r="90"></circle>
                    </g>
                </g>
                <g id="progress">
                    <circle id="Oval-6" fill="#FFFFFF" fill-rule="nonzero" mask="url(#mask-4)" cx="104" cy="104" r="71"></circle>
                </g>
                <g id="knob" transform="translate(33.000000, 33.431373)">
                    <circle id="Oval-5" fill="#FFFFFF" fill-rule="nonzero" cx="71" cy="71" r="71"></circle>
                    <line x1="71.5" y1="1" x2="71.5" y2="42" id="Line" stroke="#000000" stroke-width="12" stroke-linecap="square"></line>
                </g>
                <g id="label" transform="translate(4.000000, 80.000000)" fill="#000000" font-family="Helvetica" font-size="38" font-weight="normal" opacity="0.248604911">
                    <g id="labeltext">
                        <text id="000">
                            <tspan x="72.2993164" y="37">000</tspan>
                        </text>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`,
  updateAttributes: [
    {
      element: '#labeltext text tspan',
      content: (props, value) => {
        return value.toFixed(0)
      },
      attrs: [
        {
          name: 'text-anchor',
          value: (props, value) => {
            return 'middle'
          },
        },
        {
          name: 'x',
          value: (props, value) => {
            return '100'
          },
        },
      ],
    },
    {
      element: '#progress circle',
      attrs: [
        {
          name: 'transform-origin',
          value: (props, value) => {
            return '50% 50%'
          },
        },
        {
          name: 'transform',
          value: (props, value) => {
            return 'rotate(-180deg)'
          },
        },
        {
          name: 'stroke',
          value: (props, value) => {
            return 'tomato'
          },
        },
        {
          name: 'stroke-width',
          value: (props, value) => {
            return '12'
          },
        },
        {
          name: 'stroke-dasharray',
          value: (props, value) => {
            return (
              '' +
              (value / props.max) * 100 +
              '% ' +
              '' +
              (props.max / props.max) * 100 +
              '%'
            )
          },
        },
        {
          name: 'stroke-dashoffset',
          value: (props, value) => {
            return setProgress(value, props)
          },
        },
      ],
    },
  ],
}

// const circle = document.querySelector('#progress circle');
const radius = 24
const circumference = radius * 2 * Math.PI

function setProgress(value, props) {
  const percent = (value.toFixed(0) / props.max) * 100
  const offset = circumference - percent / 100
  return '"' + offset + '%"'
}
