/*eslint @typescript-eslint/no-unused-vars: "off"*/

import { action, observable } from 'mobx'

export type Output = {
  key: string
  name: string
}

export type Input = {
  key: string
  name: string
}

const store = observable({
  outputStatus: 'MIDI ...',
  inputStatus: 'MIDI ...',
  outputs: [] as Output[],
  inputs: [] as Input[],
  selectedOutputKey: null as string | null,
  selectedInputKey: null as string | null,
  requiresNewWindow: false,
})

export function getInputStatus() {
  return store.inputStatus
}

export function getOutputStatus() {
  return store.outputStatus
}

export function isNewWindowRequired() {
  return store.requiresNewWindow
}

export function getOutputs() {
  return store.outputs
}

export function getInputs() {
  return store.inputs
}

export function isSelected(outputKey: string, inputKey: string) {
  return (
    store.selectedOutputKey === outputKey,
    store.selectedInputKey === inputKey
  )
}


declare global {
  interface Window {
    midiAccess?: WebMidi.MIDIAccess
    midiOutput?: WebMidi.MIDIOutput
    midiInput?: WebMidi.MIDIInput
  }
}

declare var window: Window;

export const selectOutput = action(
  'selectOutput',
  (outputKey: string | null) => {
    const access = window.midiAccess
    if (!access) return
    const output = outputKey && access.outputs.get(outputKey)
    if (!output) return window.alert('No output key ' + outputKey + ' found')
    window.midiOutput = output
    setOutputStatus('MIDI-OUT')
  }
)

export const selectInput = action(
  'selectInput',
  (inputKey: string | null) => {
    const access = window.midiAccess
    if (!access) return
    const input = inputKey && access.inputs.get(inputKey)
    if (!input) return window.alert('No input key ' + inputKey + ' found')
    window.midiInput = input
    setInputStatus('MIDI-IN')
  }
)

const setOutputStatus = action('setOutputStatus', (outputStatus: string) => {
  // console.log(outputStatus);
  store.outputStatus = outputStatus
})

const setInputStatus = action('setInputStatus', (inputStatus: string) => {
  // console.log(inputStatus);
  store.inputStatus = inputStatus
})

const requireNewWindow = action('requireNewWindow', () => {
  store.inputStatus =
    'MIDI :('
  store.outputStatus =
    'MIDI :('
  store.requiresNewWindow = true
})

const handleAvailableOutputs = action(
  'handleAvailableOutputs',
  (outputs: Output[]) => {
    store.outputs = outputs
    for (const output of outputs) {
      if (store.selectedOutputKey === output.key) return
    }
    if (!store.selectedOutputKey) {
      if (outputs.length) {
        store.selectedOutputKey = outputs[0].key
      } else {
        store.selectedOutputKey = null
      }
    }
  }
)

const handleAvailableInputs = action(
  'handleAvailableInputs',
  (inputs: Input[]) => {
    store.inputs = inputs
    for (const input of inputs) {
      if (store.selectedInputKey === input.key) return
    }
    if (!store.selectedInputKey) {
      if (inputs.length) {
        store.selectedInputKey = inputs[0].key
      } else {
        store.selectedInputKey = null
      }
    }
  }
)

function ok(access: WebMidi.MIDIAccess) {
  window.midiAccess = access
  setInputStatus('MIDI ... ' + access.inputs.size)
  setOutputStatus('MIDI ... ' + access.outputs.size)
  try {
    refreshInputList(access)
    refreshOutputList(access)
  } catch (e) {
    setInputStatus('Cannot access MIDI output ' + e)
    setOutputStatus('Cannot access MIDI output ' + e)
  }
}

function refreshOutputList(access: WebMidi.MIDIAccess) {
  const ports: Output[] = []
  const iterator = access.outputs.keys()
  for (;;) {
    const { done, value: key } = iterator.next()
    if (done) break
    ports.push({ key, name: access.outputs.get(key)!.name! })
  }
  const previousKey = store.selectedOutputKey
  handleAvailableOutputs(ports)
  if (previousKey !== store.selectedOutputKey) {
    selectOutput(store.selectedOutputKey)
  }
}

function refreshInputList(access: WebMidi.MIDIAccess) {
  const ports: Input[] = []
  const iterator = access.inputs.keys()
  for (;;) {
    const { done, value: key } = iterator.next()
    if (done) break
    ports.push({ key, name: access.inputs.get(key)!.name! })
  }
  const previousKey = store.selectedInputKey
  handleAvailableInputs(ports)
  if (previousKey !== store.selectedInputKey) {
    selectInput(store.selectedInputKey)
  }
}

export function send(data: number[] | Uint8Array) {

  // iOS 👇
  if (typeof webkit !== 'undefined') {
    webkit.messageHandlers.scriptHandler.postMessage({ "type": "midi",  "command": data[0],  "number": data[1], "value": data[2] });
  }
  // Web 👇
  if (window.midiOutput) {
    window.midiOutput.send(data)
    // webkit.messageHandlers.scriptHandler.postMessage({ "name": "jeong jin eun", "age": 26 });
  } else if (window.midiInput) {
    // console.log(data)
    // window.midiInput.send(data)
  }
}

export function receive(message: any) {
  if (window.midiInput) {
      var str = "MIDI message received at timestamp " + message.timestamp + "[" + message.data.length + " bytes]: ";
      for (var i=0; i<message.data.length; i++) {
        str += "0x" + message.data[i].toString(16) + " ";
      }
  }
}

function onStateChange(access: WebMidi.MIDIAccess) {
  refreshOutputList(access)
  refreshInputList(access)
}

function init() {
  if (window.midiAccess) {
    setInputStatus('MIDI saved!!')
    setOutputStatus('MIDI saved!!')
    ok(window.midiAccess)
    return
  }
  if (navigator.requestMIDIAccess) {
    setInputStatus('MIDI ...')
    setOutputStatus('MIDI ...')
    navigator.requestMIDIAccess({ sysex: false }).then(
      access => {
        ok(access)
        access.onstatechange = () => onStateChange(access)
      },
      e => {
        if (
          String(e).match(/^SecurityError:/) &&
          window.location.hostname.match(/\.codesandbox\.io$/)
        ) {
          requireNewWindow()
        } else {
          setInputStatus('NO MIDI! ' + e)
          setOutputStatus('NO MIDI! ' + e)
        }
      }
    )
  } else if (
    typeof webkit !== 'undefined' &&
    webkit.messageHandlers &&
    webkit.messageHandlers.send &&
    webkit.messageHandlers.send.postMessage
  ) {
    ok(sham(webkit.messageHandlers.send))
  } else {
    setOutputStatus('MIDI :(')
    setInputStatus('MIDI :(')
  }
}

declare global {
  const webkit: any
}

function sham(port: any): WebMidi.MIDIAccess {
  const midiAccess = {
    outputs: new Map<string, any>(),
    inputs: new Map<string, any>(),
  }
  midiAccess.outputs.set('bluetooth', {
    name: 'Bluetooth',
    send: (bytes: number[] | Uint8Array) => port.postMessage(bytes.join(';')),
  })
  midiAccess.inputs.set('bluetooth', {
    name: 'Bluetooth',
    receive: (bytes: number[] | Uint8Array) => port.postMessage(bytes.join(';')),
  })
  return midiAccess as any
}

setTimeout(init)
