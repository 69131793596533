import React from 'react'
import * as MIDI from './MIDI'
import { Select } from 'antd'

const Option = Select.Option

class SelectMidiOutput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      val: '',
      outputs: MIDI.getOutputs(),
    }
  }

  // UNSAFE_componentWillMount() {
  //   if (window.webkit) {
  //   } else {
  //     if (this.state.val === '') {
  //       for (let i = 0; i < this.state.outputs.length; i++) {
  //         if (this.state.outputs[i].name === 'Network Session 1') {
  //           this.handleOptionSelect(this.state.outputs[i].key)
  //           this.handleChange(this.state.outputs[i].key)
  //         }
  //       }
  //     }
  //   }
  // }

  handleChange(value) {
    MIDI.selectOutput(value)
    console.log('MIDI output: ' + value)
  }

  handleOptionSelect(key) {
    this.setState({
      val: key,
    })
  }

  render() {
    let outputs = this.state.outputs
    return (
      <Select
        onChange={this.handleChange}
        placeholder={'MIDI output'}
        style={{ width: '100%', pointerEvents: 'auto' }}
      >
        {outputs.map(output => (
          <Option
            style={{ width: '100%', pointerEvents: 'auto' }}
            key={output.key}
            value={output.key}
            onSelect={() => this.handleOptionSelect(output.key)}
          >
            {output.name}
          </Option>
        ))}
      </Select>
    )
  }
}

export default SelectMidiOutput
