import { ManyToggles } from './ManyToggles'
import { createFeature } from '../core'

export default createFeature({
  name: 'manytoggles',
  category: 'instruments',
  description:
    'manytoggles',
  instruments: [
    {
      id: 'manytoggles',
      sortKey: 'manytoggles',
      name: 'manytoggles',
      description:
        'manytoggles',
      component: ManyToggles,
    },
  ],
})
