export default {
  knobX: 40,
  knobY: 40,
  svg: `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100" height="100" viewBox="0 0 100 100">
        <defs>
            <path id="maskPath" d="M50 50.5L25 100H0V0h100v100H75L50 50.5z"/>
        </defs>
        <g id="SVG">
            <g id="background">
                <circle id="backgroundCircle" fill="#444444" cx="50" cy="50" r="45"/>
            </g>
            <g id="progress">
            <mask id="mask" fill="white">
                <use xlink:href="#maskPath"/>
            </mask>
            <circle id="progressCircle" cx="50" cy="50" r="45" fill="transparent" fill-rule="nonzero" mask="url(#mask-2)"/>
            </g>
            <g id="knob" transform="translate(9.5 9.5)">
                <circle id="capCircle" cx="40.5" cy="40.5" r="45" fill="transparent" fill-rule="nonzero"/>
                <path id="capLine" stroke="#000000" stroke-linecap="square" stroke-width="4" d="M40.5 -5v23"/>
            </g>
            <g id="label" font-family="Helvetica" font-size="18.269" font-weight="normal" opacity=".249" transform="translate(1.923 38.462)">
            <text id="labelText">
                <tspan x="34.836" y="18.538">000</tspan>
            </text>
            </g>
        </g>
    </svg>
`,
  updateAttributes: [
    {
      element: '#label text tspan',
      content: (props, value) => {
        if (Number.isInteger(value)) {
          return value.toFixed(0)
        }
      },
      attrs: [
        {
          name: 'text-anchor',
          value: (props, value) => {
            return 'middle'
          },
        },
        {
          name: 'x',
          value: (props, value) => {
            return '49'
          },
        },
        {
          name: 'fill',
          value: (props, value) => {
            return 'transparent'
          },
        },
      ],
    },
    {
      element: '#knob path',
      attrs: [
        {
          name: 'stroke',
          value: (props, value) => {
            return props.bgactive
          },
        },
      ],
    },
    {
      element: '#background circle',
      attrs: [
        {
          name: 'fill',
          value: (props, value) => {
            return '' + props.bg + ''
          },
        },
        {
          name: 'stroke',
          value: (props, value) => {
            return '' + props.bg + ''
          },
        },
        {
          name: 'stroke-width',
          value: (props, value) => {
            return '2'
          },
        },
      ],
    },
    {
      element: '#progress circle',
      attrs: [
        {
          name: 'shape-rendering',
          value: (props, value) => {
            return 'geometricPrecision'
          },
        },
        {
          name: 'transform',
          value: (props, value) => {
            return 'rotate(135, 50, 50) translate(0, 0)'
          },
        },
        {
          name: 'stroke',
          value: (props, value) => {
            return '' + props.bgactive + ''
          },
        },
        {
          name: 'stroke-width',
          value: (props, value) => {
            return '4'
          },
        },
        {
          name: 'stroke-dasharray',
          value: (props, value) => {
            return '' + ((value * 1.05) / props.max) * 200 + '% 300%'
          },
        },
        {
          name: 'stroke-dashoffset',
          value: (props, value) => {
            return setProgress(value, props)
          },
        },
      ],
    },
  ],
}

// const circle = document.querySelector('#progress circle');
const radius = 50
const circumference = radius * 2 * Math.PI

function setProgress(value, props) {
  if (Number.isInteger(value)) {
    const percent = (value.toFixed(0) / props.max) * 100
    const offset = circumference - percent / 100
    return '"' + offset + '"'
  }
}
