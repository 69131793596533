import React, { Component } from 'react'
import styled from 'react-emotion'
import { Modal, Button, Popconfirm, Space, Steps } from 'antd'
import { 
  CopyOutlined,
  SnippetsOutlined,
  DeleteOutlined,
  WarningOutlined
 } from '@ant-design/icons';
import {getFromLS, saveToLS} from '../utils/LocalStorage'
// import firebase from 'firebase/app'
// import 'firebase/analytics'
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      current: 0
    }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    }
  }

  onChange = current => {
    console.log('onChange:', current);
    this.setState({ current });
  };

  handleDebug() {
    const hash = window.location.hash
    const appName =  hash.replace(/^.{2}/g, '')
    const layout = getFromLS(appName + '-currentLayout') || []
    var aux = document.createElement('input')
    aux.setAttribute('value', JSON.stringify(layout))
    document.body.appendChild(aux)
    aux.select()
    document.execCommand('copy')
    document.body.removeChild(aux)
  }
  handleBackup() {
    const hash = window.location.hash
    const appName =  hash.replace(/^.{2}/g, '')
    const layout = getFromLS(appName + '-currentLayout') || []
    const templates = getFromLS(appName + '-templates') || []
    const saved = getFromLS(appName + '-saved') || []
    var aux = document.createElement('input')
    aux.setAttribute('value', JSON.stringify(layout, templates, saved))
    document.body.appendChild(aux)
    aux.select()
    document.execCommand('copy')
    document.body.removeChild(aux)
  }

  handleDeleteCurrent() {
    const hash = window.location.hash
    const appName =  hash.replace(/^.{2}/g, '')
    const layout = {
      name: '',
      author: '',
      bg: '#000000',
      layout: [], 
    }
    saveToLS(appName + '-currentLayout', layout)
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }
  handleDeleteAll() {
    const hash = window.location.hash
    const appName =  hash.replace(/^.{2}/g, '')
    const layout = {
      name: '',
      author: '',
      bg: '',
      layout: [], 
    }
    const templates = []
    const saved = []
    saveToLS(appName + '-currentLayout', layout)
    saveToLS(appName + '-templates', templates)
    saveToLS(appName + '-saved', saved)
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }

  render() {
    const { Step } = Steps;
    if (this.state.hasError) {
      return (
        <Modal
          title="Oops! Error error error"
          visible={true}
          closable={false}
          footer={null}
          centered
        >
        <Space
            direction="vertical"
            style={{ width: '100%' }}
          >
          <p>An error has happened. Here are some suggestions for troubleshooting:</p>
          <Steps current={this.state.current} onChange={this.onChange} direction="vertical">
            <Step 
            icon={<CopyOutlined/>}
            description={
              <div>
                <p>Backup allthethings!</p>
                <Button type="primary" onClick={() => this.handleBackup()} icon={<CopyOutlined/>}>Copy all data</Button>
              </div>
            } />
            <Step 
            icon={<SnippetsOutlined/>}
            description={
              <div>
                <p>Copy the current layout in JSON and check for errors. When you think it's fixed try pasting it back.</p>
                <Button type="primary" onClick={() => this.handleDebug()} icon={<CopyOutlined/>}>Copy current layout</Button>
                 &nbsp;
                <Button type="primary" onClick={() => this.handleDebug()} icon={<SnippetsOutlined/>}>Paste from clipboard</Button>
              </div>
            } />
            <Step 
              icon={<DeleteOutlined/>}
              description={
              <div>
                <p>If your layout still gives an error, you can delete it from memory and load an empty one instead.</p>
                <StyledPopconfirm
                  cancelText={'Cancel'}
                  okText={'Delete'}
                  onConfirm={() => this.handleDeleteCurrent()}
                   icon={null} 
                   title="Are you sure?">
                  <Button type="primary" danger icon={<DeleteOutlined/>}>Delete layout</Button>
                </StyledPopconfirm>
              </div>
            } />
            <Step 
              icon={<WarningOutlined/>}
              description={
              <div>
                <p>or you can also restore everything to factory settings. This action will erase all saved templates and preferences.</p>
                <StyledPopconfirm
                  cancelText={'Cancel'}
                  okText={'Delete'}
                  onConfirm={() => this.handleDeleteAll()}
                  icon={null} 
                  title="Are you sure?">
                  <Button type="primary" danger icon={<WarningOutlined/>}>Delete allthethings</Button>
                </StyledPopconfirm>
              </div>
            } />
          </Steps>
        </Space>
        </Modal>
      )
    }
    return this.props.children
  }
}
export default ErrorBoundary


const MyPopconfirm = ({ className, ...props }) => <Popconfirm {...props} overlayClassName={className} />

const StyledPopconfirm = styled(MyPopconfirm)`
  .ant-popover-content {
    background: #333;
    padding: 1em 2em;
    .ant-popover-arrow {
      border-right-color: #333;
      border-bottom-color: #333;
    }
  }
  .ant-popover-message {
    text-align: center;
  }
  .ant-popover-message-title {
    padding-left: 0;
  }
  .ant-popover-buttons button:first-child{
    margin-left: 0;
  }
`