import './App.less'
import MainView from './MainView'
import React from 'react'
import styled from 'react-emotion'
import { HashRouter } from 'react-router-dom'
import { Feature } from './types'

const Wrapper = styled('div')`
  overflow: hidden;
  height: 100%;
  pointer-events: all;
`

const AppContent = styled('div')`
  // position: absolute;
  // top: 0px;
  // right: 0;
  // bottom: 0;
  // left: 0;
`

export function App({ features }: { features: Feature[] }) {
  return (
    <HashRouter>
      <Wrapper>
        <AppContent>
            <MainView features={features} />
        </AppContent>
      </Wrapper>
    </HashRouter>
  )
}
