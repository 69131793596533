import { AllTheMidi } from './AllTheMidi'
import { createFeature } from '../core'

export default createFeature({
  name: 'allthemidi',
  category: 'instruments',
  description:
    'allthemidi',
  instruments: [
    {
      id: 'allthemidi',
      sortKey: 'allthemidi',
      name: 'allthemidi',
      description:
        'allthemidi',
      component: AllTheMidi,
    },
  ],
})
