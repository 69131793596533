import React from 'react'
import styled from 'react-emotion'
import { Tabs, Space, Button, Input, Drawer, Select, Divider, Modal } from 'antd'
import ColorPicker from '../utils/ColorPicker'
import { getFromLS, saveToLS } from '../utils/LocalStorage'
import { logEvent } from '../utils/Firebase'
import SelectMidiOutput from '../core/midi/SelectMidiOutput'
import SelectMidiInput from '../core/midi/SelectMidiInput'
import LayoutTemplates from './LayoutTemplates'
import { SettingsIcon } from './LayoutIcons'
import LayoutJson from './LayoutJson'


class EditDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      data: this.props.data,
      templates: this.props.templates,
      saved: this.props.saved,
      unlocked: this.props.unlocked,
      modalVisible: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.loadLayoutTemplate = this.loadLayoutTemplate.bind(this)
    this.handleSaveLayout = this.handleSaveLayout.bind(this)
    this.checkSavedLayouts = this.checkSavedLayouts.bind(this)
    this.handleDeleteLayout = this.handleDeleteLayout.bind(this)
    this.handleModalOk = this.handleModalOk.bind(this)
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  handleClose = e => {
    this.setState({
      visible: false,
    })
  }

  showModal = () => {
    setTimeout(() => {
      this.setState({
        modalVisible: true,
      });
    }, 200);
  };

  handleModalOk = e => {
    // console.log(e);
    copyToClipboard(this.props.data)
    this.setState({
      modalVisible: false,
    });
    // window.location.reload()
  };

  handleModalCancel = e => {
    // console.log(e);
    this.setState({
      modalVisible: false,
    });
    window.location.reload()
  };


  handleInputChange(e) {
    this.props.handleEditLayout(e)
  }

  handleSaveLayout() {
    const hash = window.location.hash
    const appName = hash.replace(/^.{2}/g, '')
    const saved = getFromLS(appName + '-saved') || []

    const savedUpdated = []
    var unique = true
    saved.map((template) => {
      if (template.name === this.props.data.name) {
        console.log('name already exists: overwrite saved one')
        savedUpdated.push(this.props.data)
        unique = false
      }
      else {
        console.log('name does not exist: keep saved one')
        savedUpdated.push(template)
      }
    })
    if (unique) {
      console.log('name is unique: add new one')
      savedUpdated.push(this.props.data)
    }

    // Log Firebase event
    logEvent('layout-save', {
      layout_name: this.props.data.name,
    })
    saveToLS(appName + '-saved', savedUpdated)
    // this.showModal()
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }

  checkSavedLayouts() {
    const hash = window.location.hash
    const appName = hash.replace(/^.{2}/g, '')
    const saved = getFromLS(appName + '-saved') || []
    var unique = true
    saved.map((template) => {
      if (template.name === this.props.data.name) {
        unique = false
      }
    })
    return unique
  }

  handleDeleteLayout() {
    const hash = window.location.hash
    const appName = hash.replace(/^.{2}/g, '')
    const saved = getFromLS(appName + '-saved') || []

    const savedUpdated = []
    saved.map((template) => {
      if (template.name === this.state.data.name) {
        // savedUpdated.push(this.state.data)
      }
      else {
        savedUpdated.push(template)
      }
    })

    // Log Firebase event
    logEvent('layout-delete', {
      layout_name: this.state.data.name,
    })
    saveToLS(appName + '-saved', savedUpdated)
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }

  getOptionsForLayout() {
    return {
      name: 'Layout',
      options: [
        {
          name: 'name',
          label: 'Name',
          type: 'string',
          defaultValue: this.props.data.name,
        },
        {
          name: 'author',
          label: 'Author',
          type: 'string',
          defaultValue: this.props.data.author,
        },
        {
          name: 'bg',
          label: 'Background',
          type: 'color',
          defaultValue: this.props.data.bg,
        },
        // {
        //   name: 'width',
        //   label: 'Width',
        //   type: 'string',
        //   defaultValue: this.state.data.width,
        // },
        // {
        //   name: 'height',
        //   label: 'Height',
        //   type: 'select',
        //   defaultValue: this.state.data.height || '',
        //   options: ['100vh', '50vh']
        // },
        // {
        //   name: 'cols',
        //   label: 'Columns',
        //   type: 'string',
        //   defaultValue: this.state.data.cols || ''
        // },
      ],
      instructions: [
        {
          title: 'What?',
          text:
            'Design your own custom MIDI controllers and send MIDI messages to other apps & hardware.',
        },
        {
          title: 'How?',
          text:
            "This app works in combination with Audio Unit apps that listen for MIDI events. A great free app that allows sending MIDI messages to other apps & devices via Bluetooth or USB cable is ",
          link: 
          // <a style={{ color: 'inherit', paddingBottom: '2px', borderBottom: '1px solid rgba(255,255,255,0.65)' }} href="https://apps.apple.com/us/app/midimittr/id925495245">{'Midimittr.'}</a>,
          <Button style={{ color: 'inherit', padding: '0', fontSize: '13px', height: 'auto' }} type="link">{'Midimittr.'}</Button>,
        },
        {
          title: 'Basic',
          text:
            'The main MIDI properties for each component (channel, note, cc) as well as the customisation options (color, direction, text) can be changed using the controllers shown in the screen.',
        },
        {
          title: 'Advanced',
          text:
            'Inspecting the underlying data structure in json format will give you access to more tweaks. Check the existing templates to know more about some of the possibilities.',
        },
        // {
        //   title: 'Help',
        //   text:
        //     'If you need support with your layouts or have any feedback or ideas to improve the app just go ahead and ',
        //   link:
        //     <a style={{ color: 'inherit', paddingBottom: '2px', borderBottom: '1px solid rgba(255,255,255,0.65)' }} href="mailto:allthemidi@gmail.com">{'get in touch.'}</a>
        // },
      ],
    }
  }

  loadLayoutTemplate(value) {
    const hash = window.location.hash
    const appName = hash.replace(/^.{2}/g, '')
    const templateType = value.substr(0,value.indexOf('-'))
    const templateName = value.substr(value.indexOf('-')+1)
    const templates = getFromLS(appName + '-templates') || []
    // const templates = this.state.templates
    const saved = getFromLS(appName + '-saved') || []
    

    if (templateType === 'saved') {
      saved.map((template) => {
        if (template.name === templateName) {
          setTimeout(() => {
            saveToLS(appName + '-currentLayout', template)
            window.location.reload()
          }, 200);
        }
      })
    } else if (templateType === 'template') {
      if (templateName === 'Empty') {
        setTimeout(() => {
          saveToLS(appName + '-currentLayout', {
            name: '',
            author: '',
            bg: '',
            layout: [],
        })
          window.location.reload()
        }, 200);
      } else {
        templates.map((template) => {
          if (template.name === templateName) {
            setTimeout(() => {
              saveToLS(appName + '-currentLayout', template)
              window.location.reload()
            }, 200);
          }
        })
      }
    }
    // Log Firebase event
    logEvent('layout-load', {
      layout_name: templateName,
    })
    
    
  }

  render() {
    const { TabPane } = Tabs

    return (
      <div>
        {/* <Modal
          title={null}
          closable={false}
          okText={'Okay'}
          cancelText={'Copy to clipboard'}
          centered
          visible={this.state.modalVisible}
          onOk={this.handleModalCancel}
          onCancel={this.handleModalOk}
        >
          <h3>Saved: {this.props.data.name}</h3>
          <p>Copy the current layout to share it accross devices.</p>
        </Modal> */}
        <SettingsToggle onClick={() => this.showDrawer()}>
          {this.props.unlocked ? (
            <div>
              <SettingsIcon style={{ fontSize: '1.3em',color: 'rgba(255,255,255,1)'}} />
            </div>
          ) : (
            <div style={{filter: 'grayscale(100%)'}}>
            <div style={{mixBlendMode: 'difference', position: 'absolute'}}>
                <SettingsIcon style={{ mixBlendMode: 'difference', fontSize: '1.3em' }} />
            </div>
            <div>
                <SettingsIcon style={{ fontSize: '1.3em',color: this.props.data.bg || "#000000"}} />
            </div>
          </div>
          )}
        </SettingsToggle>
        
        <StyledDrawer
          placement="right"
          closable={true}
          maskClosable={true}
          onClose={this.handleClose}
          destroyOnClose={true}
          title={'Settings'}
          visible={this.state.visible}
          maskStyle={{ background: 'rgba(0,0,0,0.1)' }}
          bodyStyle={{ paddingTop: '0' }}
          headerStyle={{ display: 'none' }}
          width={Math.min(350, document.documentElement.clientWidth * 0.9)}
        >
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: '2em' }}
          >
            <Tabs defaultActiveKey="1">
              <TabPane tab="Layout" key="1">
                <LayoutTemplates
                  data={this.state.data}
                  templates={this.state.templates}
                  saved={this.state.saved}
                  onLoadLayoutTemplate={template =>
                    this.loadLayoutTemplate(template)
                  }
                />

                {this.getOptionsForLayout().options.map((option, i) => {
                  return (
                    <div key={i}>
                      <Divider orientation="left" plain>
                        {option.label}
                      </Divider>
                      {option.type === 'color' ? (
                        <ColorPicker
                          data={this.props.data}
                          name={option.name}
                          currentColor={option.defaultValue}
                          onColorChoose={e => this.handleInputChange(e)}
                        />
                      ) : option.type === 'select' ? (
                        <Select
                          showArrow
                          style={{ width: '100%' }}
                          defaultValue={option.defaultValue}
                          onChange={value =>
                            this.handleInputChange({
                              target: {
                                name: option.name,
                                value: value,
                              },
                            })
                          }
                        >
                          {option.options.map(option => (
                            <Select.Option value={option} key={option}>
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : option.type === 'number' || 'string' ? (
                        <Input
                          min={option.min}
                          max={option.max}
                          addonBefore={null}
                          type={option.type}
                          name={option.name}
                          defaultValue={option.defaultValue}
                          onChange={e =>
                            this.handleInputChange({
                              target: {
                                name: e.target.name,
                                value: e.target.value,
                              },
                            })
                          }
                        />
                      ) : null}
                    </div>
                  )
                })}

                <Divider orientation="left" plain>
                  Actions
                </Divider>
                <Space
                    direction="vertical"
                    style={{ width: '100%', marginBottom: '2em' }}
                  >
                    <Button
                      type="primary"
                      block
                      onClick={() => this.handleSaveLayout()}>
                      {this.checkSavedLayouts() ? 'Save' : 'Save'}
                    </Button>
                    {this.checkSavedLayouts() ? null : 
                      <Button
                      type="danger"
                      block
                      onClick={() => this.handleDeleteLayout()}>
                      {'Delete'}
                    </Button>
                    }
                  </Space>
              </TabPane>

              {window.webkit ? null : (
                <TabPane tab="MIDI" key="2">
                  <Space
                    direction="vertical"
                    style={{ width: '100%', marginBottom: '2em' }}
                  >
                    <SelectMidiInput />
                    <SelectMidiOutput />
                  </Space>
                </TabPane>
              )}

              <TabPane tab="[ ··· ]" key="3">
                <Space
                  direction="vertical"
                  style={{ width: '100%', marginBottom: '2em' }}
                >
                  <Space
                    direction="vertical"
                    style={{ width: '100%', marginBottom: '2em' }}
                  >
                    <Button
                      onClick={() => copyToClipboard(this.props.data)}
                      block
                    >
                      Copy layout to clipboard
                    </Button>
                    <Button onClick={() => pasteFromClipboard()} block>
                      Paste layout from clipboard
                    </Button>
                    <Divider orientation="left" plain>
                      {'Layout JSON'}
                    </Divider>
                    <LayoutJson
                      data={this.props.data}
                      onEdit={edit =>
                        this.handleInputChange({
                          target: {
                            name: edit.name,
                            value: edit.new_value,
                          },
                        })
                      }
                    />
                  </Space>
                </Space>
              </TabPane>

              <TabPane tab="?" key="4">
                <Space
                  direction="vertical"
                  style={{ width: '100%', marginBottom: '2em' }}
                >
                  {this.getOptionsForLayout().instructions.map(
                    (instruction, i) => {
                      return (
                        <div key={'instructions' + i}>
                          <Divider orientation="left" plain style={{color: '#ffffff'}}>
                            {instruction.title}
                          </Divider>
                          <p style={{ fontSize: '13px' }}>
                            {instruction.text}{instruction.link}
                            </p>
                        </div>
                      )
                    }
                  )}
                </Space>
              </TabPane>
            </Tabs>
            {this.props.children}
          </Space>
        </StyledDrawer>
      </div>
    )
  }
}

export default EditDrawer

function copyToClipboard(layout) {
  var aux = document.createElement('input')
  aux.setAttribute('value', JSON.stringify(layout))
  document.body.appendChild(aux)
  aux.select()
  document.execCommand('copy')
  document.body.removeChild(aux)

  // Log Firebase event
  logEvent('layout-copy')
}

async function pasteFromClipboard() {
  // TODO: Improve pasteFromClipboard. On iOS it shows a tooltip saying 'Paste' so it's a two-step action right now :/
  const copy = await navigator.clipboard.readText()

  if (window) {
    const hash = window.location.hash
    var appName = hash.replace(/^.{2}/g, '')

    if (global.localStorage) {
      global.localStorage.setItem(
        appName + '-currentLayout',
        JSON.stringify({
          [appName + '-currentLayout']: JSON.parse(copy),
        })
      )
    }

    // Log Firebase event
    logEvent('layout-paste')

    setTimeout(() => {
      window.location.reload()
    }, 200)
  }
}

const SettingsToggle = styled('div')`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    opacity: 0.5
  }
`

const StyledDrawer = styled(Drawer)`
    /* iPhone X and Xs Max */
  @media only screen 
      and (device-width: 375px) 
      and (device-height: 812px) 
      and (-webkit-device-pixel-ratio: 3)
      and (orientation : portrait) { 
        .ant-drawer-body {
          padding-top: 40px !important;
        }
      }
  }
  /* iPhone XR */
  @media only screen 
      and (device-width: 414px) 
      and (device-height: 896px) 
      and (-webkit-device-pixel-ratio: 2) 
      and (orientation : portrait) { 
        .ant-drawer-body {
          padding-top: 40px !important;
        }
      }
  }
  /* iPhone 11 – 1792x828px at 326ppi */
  @media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) 
    and (orientation : portrait) { 
        .ant-drawer-body {
          padding-top: 40px !important;
        }
      }
  /* iPhone 11 Pro – 2436x1125px at 458ppi */
  @media only screen 
      and (device-width: 375px) 
      and (device-height: 812px) 
      and (-webkit-device-pixel-ratio: 3)
      and (orientation : portrait) { 
        .ant-drawer-body {
          padding-top: 40px !important;
        }
      }
  /* iPhone 11 Pro Max – 2688x1242px at 458ppi */
  @media only screen 
      and (device-width: 414px) 
      and (device-height: 896px) 
      and (-webkit-device-pixel-ratio: 3) 
      and (orientation : portrait) { 
        .ant-drawer-body {
          padding-top: 40px !important;
        }
      }
    }
  }
`