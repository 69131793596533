export const demo = () => {
  const cols = document.documentElement.clientWidth / 8
  let data;
  if (document.documentElement.clientWidth > 1024) {
    data = {
      "name": "Demo",
      "author": "",
      "bg": "#444444",
      "colors": [
          "#f0f0f0",
          "#eeeeee",
          "#999999",
          "#666666",
          "#222222",
          "#333333",
          "#222222",
          "#000000",
      ],
      "cols": "",
      "width": "",
      "height": "",
      "layout": [
        {
          "i": "n0",
          "w": cols * 0.3333333,
          "h": 8,
          "x": cols * 0.3333333,
          "y": 30,
          "moved": false,
          "static": false,
          "component": "Text",
          "content": {
            "text": "allthemidi - demo",
            "bg": "transparent",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n1",
          "w": cols * 0.3333333,
          "h": 0.2,
          "x": cols * 0.3333333,
          "y": 18 + 20,
          "moved": false,
          "static": false,
          "component": "Text",
          "content": {
            "text": "",
            "bg": "rgba(255,255,255,0.3)",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n2",
          "w": cols * 0.3333333,
          "h": 10,
          "x": cols * 0.3333333,
          "y": 21 + 20,
          "moved": false,
          "static": false,
          "component": "Text",
          "content": {
            "text": "This is the demo layout. Try a few of the components below or make changes using the buttons at the top",
            "bg": "transparent",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n3",
          "w": cols * 0.125,
          "h": 10,
          "x": cols * 0.25,
          "y": 40 + 20,
          "moved": false,
          "static": false,
          "component": "Knob",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n4",
          "w": cols * 0.125,
          "h": 10,
          "x": cols * 0.25 + cols * 0.125,
          "y": 40 + 20,
          "moved": false,
          "static": false,
          "component": "Knob",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n5",
          "w": cols * 0.125,
          "h": 10,
          "x": cols * 0.25 + cols * 0.125 * 2,
          "y": 40 + 20,
          "moved": false,
          "static": false,
          "component": "Knob",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n6",
          "w": cols * 0.125,
          "h": 10,
          "x": cols * 0.25 + cols * 0.125 * 3,
          "y": 40 + 20,
          "moved": false,
          "static": false,
          "component": "Knob",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n7",
          "w": cols * 0.125 - 16,
          "h": 30,
          "x": cols * 0.25 + 8,
          "y": 60 + 20,
          "moved": false,
          "static": false,
          "component": "Fader",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#333333",
            "direction": "vertical"
          }
        },
        {
          "i": "n8",
          "w": cols * 0.125 - 16,
          "h": 30,
          "x": cols * 0.25 + cols * 0.125 + 8,
          "y": 60 + 20,
          "moved": false,
          "static": false,
          "component": "Fader",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#333333",
            "direction": "vertical"
          }
        },
        {
          "i": "n9",
          "w": cols * 0.125 - 16,
          "h": 30,
          "x": cols * 0.25 + cols * 0.125 * 2 + 8,
          "y": 60 + 20,
          "moved": false,
          "static": false,
          "component": "Fader",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#333333",
            "direction": "vertical"
          }
        },
        {
          "i": "n10",
          "w": cols * 0.125 - 16,
          "h": 30,
          "x": cols * 0.25 + cols * 0.125 * 3 + 8,
          "y": 60 + 20,
          "moved": false,
          "static": false,
          "component": "Fader",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#333333",
            "direction": "vertical"
          }
        },
        {
          "i": "n11",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25,
          "y": 100 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 48,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n12",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125,
          "y": 100 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 49,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n13",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 2,
          "y": 100 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 50,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n14",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 3,
          "y": 100 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 51,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n15",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25,
          "y": 100 + cols * 0.125 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 44,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n16",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125,
          "y": 100 + cols * 0.125 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 45,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n17",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 2,
          "y": 100 + cols * 0.125 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 46,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n18",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 3,
          "y": 100 + cols * 0.125 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 47,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n19",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25,
          "y": 100 + cols * 0.125 * 2 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 40,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n20",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125,
          "y": 100 + cols * 0.125 * 2 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 41,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n21",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 2,
          "y": 100 + cols * 0.125 * 2 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 42,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n22",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 3,
          "y": 100 + cols * 0.125 * 2 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 43,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n23",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25,
          "y": 100 + cols * 0.125 * 3 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 36,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n24",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125,
          "y": 100 + cols * 0.125 * 3 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 37,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n25",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 2,
          "y": 100 + cols * 0.125 * 3 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 38,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n26",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 3,
          "y": 100 + cols * 0.125 * 3 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 39,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n27",
          "w": cols * 0.3333333,
          "h": 30,
          "x": cols * 0.3333333,
          "y": 201 + 20,
          "moved": false,
          "static": false,
          "component": "Text",
          "content": {
            "text": "",
            "bg": "transparent",
            "bgactive": "#ffffff"
          }
        },
        ]
    }
  }
  else if (document.documentElement.clientWidth <= 768) {
    data = {
      "name": "Demo",
      "author": "",
      "bg": "#444444",
      "colors": [
          "#f0f0f0",
          "#eeeeee",
          "#999999",
          "#666666",
          "#222222",
          "#333333",
          "#222222",
          "#000000",
      ],
      "cols": "",
      "width": "",
      "height": "",
      "layout": [
        {
          "i": "n0",
          "w": cols * 0.8,
          "h": 8,
          "x": cols * 0.1,
          "y": 10,
          "moved": false,
          "static": false,
          "component": "Text",
          "content": {
            "text": "allthemidi - demo",
            "bg": "transparent",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n1",
          "w": cols * 0.8,
          "h": 0.2,
          "x": cols * 0.1,
          "y": 18,
          "moved": false,
          "static": false,
          "component": "Text",
          "content": {
            "text": "",
            "bg": "rgba(255,255,255,0.3)",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n2",
          "w": cols * 0.8,
          "h": 10,
          "x": cols * 0.1,
          "y": 21,
          "moved": false,
          "static": false,
          "component": "Text",
          "content": {
            "text": "This is the demo layout. Try a few of the components below or make changes using the buttons at the top",
            "bg": "transparent",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n3",
          "w": cols * 0.125,
          "h": 10,
          "x": cols * 0.0625,
          "y": 40,
          "moved": false,
          "static": false,
          "component": "Knob",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n4",
          "w": cols * 0.125,
          "h": 10,
          "x": cols * 0.0625 + cols * 0.25,
          "y": 40,
          "moved": false,
          "static": false,
          "component": "Knob",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n5",
          "w": cols * 0.125,
          "h": 10,
          "x": cols * 0.0625 + cols * 0.25 * 2,
          "y": 40,
          "moved": false,
          "static": false,
          "component": "Knob",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n6",
          "w": cols * 0.125,
          "h": 10,
          "x": cols * 0.0625 + cols * 0.25 * 3,
          "y": 40,
          "moved": false,
          "static": false,
          "component": "Knob",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n7",
          "w": cols/12,
          "h": 25,
          "x": cols/12,
          "y": 55,
          "moved": false,
          "static": false,
          "component": "Fader",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#333333",
            "direction": "vertical"
          }
        },
        {
          "i": "n8",
          "w": cols/12,
          "h": 25,
          "x": cols/12 * 4,
          "y": 55,
          "moved": false,
          "static": false,
          "component": "Fader",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#333333",
            "direction": "vertical"
          }
        },
        {
          "i": "n9",
          "w": cols/12,
          "h": 25,
          "x": cols/12 * 7,
          "y": 55,
          "moved": false,
          "static": false,
          "component": "Fader",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#333333",
            "direction": "vertical"
          }
        },
        {
          "i": "n10",
          "w": cols/12,
          "h": 25,
          "x": cols/12 * 10,
          "y": 55,
          "moved": false,
          "static": false,
          "component": "Fader",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#333333",
            "direction": "vertical"
          }
        },
        {
          "i": "n11",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0,
          "y": 100 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 48,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n12",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0 + cols * 0.25,
          "y": 100 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 49,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n13",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0 + cols * 0.25 * 2,
          "y": 100 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 50,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n14",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0 + cols * 0.25 * 3,
          "y": 100 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 51,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n15",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0,
          "y": 100 + cols * 0.25 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 44,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n16",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0 + cols * 0.25,
          "y": 100 + cols * 0.25 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 45,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n17",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0 + cols * 0.25 * 2,
          "y": 100 + cols * 0.25 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 46,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n18",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0 + cols * 0.25 * 3,
          "y": 100 + cols * 0.25 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 47,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n19",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0,
          "y": 100 + cols * 0.25 * 2 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 40,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n20",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0 + cols * 0.25,
          "y": 100 + cols * 0.25 * 2 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 41,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n21",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0 + cols * 0.25 * 2,
          "y": 100 + cols * 0.25 * 2 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 42,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n22",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0 + cols * 0.25 * 3,
          "y": 100 + cols * 0.25 * 2 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 43,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n23",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0,
          "y": 100 + cols * 0.25 * 3 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 36,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n24",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0 + cols * 0.25,
          "y": 100 + cols * 0.25 * 3 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 37,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n25",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0 + cols * 0.25 * 2,
          "y": 100 + cols * 0.25 * 3 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 38,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n26",
          "w": cols * 0.25,
          "h": cols * 0.25,
          "x": 0 + cols * 0.25 * 3,
          "y": 100 + cols * 0.25 * 3 - 10,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 39,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n27",
          "w": cols * 0.3333333,
          "h": 20,
          "x": cols * 0.3333333,
          "y": 200,
          "moved": false,
          "static": false,
          "component": "Text",
          "content": {
            "text": "",
            "bg": "transparent",
            "bgactive": "#ffffff"
          }
        },
        ]
    }
  }
  else if (document.documentElement.clientWidth <= 1024) {
    data = {
      "name": "Demo",
      "author": "",
      "bg": "#444444",
      "colors": [
          "#f0f0f0",
          "#eeeeee",
          "#999999",
          "#666666",
          "#222222",
          "#333333",
          "#222222",
          "#000000",
      ],
      "cols": "",
      "width": "",
      "height": "",
      "layout": [
        {
          "i": "n0",
          "w": cols * 0.3333333,
          "h": 8,
          "x": cols * 0.3333333,
          "y": 10 + 20,
          "moved": false,
          "static": false,
          "component": "Text",
          "content": {
            "text": "allthemidi - demo",
            "bg": "transparent",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n1",
          "w": cols * 0.3333333,
          "h": 0.2,
          "x": cols * 0.3333333,
          "y": 18 + 20,
          "moved": false,
          "static": false,
          "component": "Text",
          "content": {
            "text": "",
            "bg": "rgba(255,255,255,0.3)",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n2",
          "w": cols * 0.3333333,
          "h": 10,
          "x": cols * 0.3333333,
          "y": 21 + 20,
          "moved": false,
          "static": false,
          "component": "Text",
          "content": {
            "text": "This is the demo layout. Try a few of the components below or make changes using the buttons at the top",
            "bg": "transparent",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n3",
          "w": cols * 0.125,
          "h": 10,
          "x": cols * 0.25,
          "y": 40 + 20,
          "moved": false,
          "static": false,
          "component": "Knob",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n4",
          "w": cols * 0.125,
          "h": 10,
          "x": cols * 0.25 + cols * 0.125,
          "y": 40 + 20,
          "moved": false,
          "static": false,
          "component": "Knob",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n5",
          "w": cols * 0.125,
          "h": 10,
          "x": cols * 0.25 + cols * 0.125 * 2,
          "y": 40 + 20,
          "moved": false,
          "static": false,
          "component": "Knob",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n6",
          "w": cols * 0.125,
          "h": 10,
          "x": cols * 0.25 + cols * 0.125 * 3,
          "y": 40 + 20,
          "moved": false,
          "static": false,
          "component": "Knob",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#ffffff"
          }
        },
        {
          "i": "n7",
          "w": cols * 0.125 - 10,
          "h": 30,
          "x": cols * 0.25 + 5,
          "y": 60 + 20,
          "moved": false,
          "static": false,
          "component": "Fader",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#333333",
            "direction": "vertical"
          }
        },
        {
          "i": "n8",
          "w": cols * 0.125 - 10,
          "h": 30,
          "x": cols * 0.25 + cols * 0.125 + 5,
          "y": 60 + 20,
          "moved": false,
          "static": false,
          "component": "Fader",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#333333",
            "direction": "vertical"
          }
        },
        {
          "i": "n9",
          "w": cols * 0.125 - 10,
          "h": 30,
          "x": cols * 0.25 + cols * 0.125 * 2 + 5,
          "y": 60 + 20,
          "moved": false,
          "static": false,
          "component": "Fader",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#333333",
            "direction": "vertical"
          }
        },
        {
          "i": "n10",
          "w": cols * 0.125 - 10,
          "h": 30,
          "x": cols * 0.25 + cols * 0.125 * 3 + 5,
          "y": 60 + 20,
          "moved": false,
          "static": false,
          "component": "Fader",
          "content": {
            "text": "",
            "channel": 1,
            "note": 36,
            "value": 0,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#333333",
            "direction": "vertical"
          }
        },
        {
          "i": "n11",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25,
          "y": 100 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 48,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n12",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125,
          "y": 100 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 49,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n13",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 2,
          "y": 100 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 50,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n14",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 3,
          "y": 100 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 51,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n15",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25,
          "y": 100 + cols * 0.125 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 44,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n16",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125,
          "y": 100 + cols * 0.125 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 45,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n17",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 2,
          "y": 100 + cols * 0.125 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 46,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n18",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 3,
          "y": 100 + cols * 0.125 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 47,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n19",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25,
          "y": 100 + cols * 0.125 * 2 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 40,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n20",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125,
          "y": 100 + cols * 0.125 * 2 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 41,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n21",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 2,
          "y": 100 + cols * 0.125 * 2 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 42,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n22",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 3,
          "y": 100 + cols * 0.125 * 2 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 43,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n23",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25,
          "y": 100 + cols * 0.125 * 3 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 36,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n24",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125,
          "y": 100 + cols * 0.125 * 3 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 37,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n25",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 2,
          "y": 100 + cols * 0.125 * 3 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 38,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n26",
          "w": cols * 0.125,
          "h": cols * 0.125,
          "x": cols * 0.25 + cols * 0.125 * 3,
          "y": 100 + cols * 0.125 * 3 + 20,
          "moved": false,
          "static": false,
          "component": "Pad",
          "content": {
            "velocity": "fixed",
            "channel": 1,
            "note": 39,
            "value": 127,
            "min": 1,
            "max": 127,
            "bg": "#222222",
            "bgactive": "#999999"
          }
        },
        {
          "i": "n27",
          "w": cols * 0.3333333,
          "h": 30,
          "x": cols * 0.3333333,
          "y": 200,
          "moved": false,
          "static": false,
          "component": "Text",
          "content": {
            "text": "",
            "bg": "transparent",
            "bgactive": "#ffffff"
          }
        },
        ]
    }
  }
  return data
};

export default demo