import { ManyFaders } from './ManyFaders'
import { createFeature } from '../core'

export default createFeature({
  name: 'manyfaders',
  category: 'instruments',
  description:
    'manyfaders',
  instruments: [
    {
      id: 'manyfaders',
      sortKey: 'manyfaders',
      name: 'manyfaders',
      description:
        'manyfaders',
      component: ManyFaders,
    },
  ],
})
