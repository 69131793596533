export const demo = () => {
  const width = document.documentElement.clientWidth
  const height = document.documentElement.clientHeight
  const portrait = width < height
  const landscape = height < width
  let layout = []
  let index = 0
  var itemW
  var itemH

  if (landscape) {
    itemW = width / 8 / 5 - 0.2
    itemH = height / 8 / 4 - 0.2
    const margin = itemW / 10 - 0.4
    for (let i = 0; i < 10; i++) {
      for (let j = 0; j < 4; j++) {
        index++
        let row
        row = {
          "i": "n" + index,
          "w": itemW - margin - 10,
          "h": itemH - margin - 10,
          "x": itemW * j + margin + 20,
          "y": itemH * i + margin + 20,
          "component": "Knob",
          "content": {
            "channel": 1,
            "note": index,
            "value": 0,
            "bg": "#222222",
            "bgactive": "#FFFFFF"
          }
        }
        layout.push(row)
      }
    }
  }
  if (portrait) {
    itemW = width / 8 / 4
    itemH = height / 8 / 5
    const margin = itemW / 10 
    for (let i = 0; i < 10; i++) {
      for (let j = 0; j < 4; j++) {
        index++
        let row
        row = {
          "i": "n" + index,
          "w": itemW - margin,
          "h": itemH - margin,
          "x": itemW * j + margin,
          "y": itemH * i + margin,
          "component": "Knob",
          "content": {
            "channel": 1,
            "note": index,
            "value": 0,
            "bg": "#222222",
            "bgactive": "#FFFFFF"
          }
        }
        layout.push(row)
      }
    }
  }


  let data = {
    name: 'Demo',
    author: '',
    bg: '#444444',
    layout: layout,
  }
  return data
}

export default demo