import { ManyKnobs } from './ManyKnobs'
import { createFeature } from '../core'

export default createFeature({
  name: 'manyknobs',
  category: 'instruments',
  description:
    'manyknobs',
  instruments: [
    {
      id: 'manyknobs',
      sortKey: 'manyknobs',
      name: 'manyknobs',
      description:
        'manyknobs',
      component: ManyKnobs,
    },
  ],
})
