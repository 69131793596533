import React from 'react'
import ReactJson from 'react-json-view'

export default class InstrumentJson extends React.Component {

  render() {
    const { 
      ['static']: hideKey1,
      ['moved']: hideKey2,
      ...json } = this.props.data;

    return (
        <ReactJson 
        src={json} 
        shouldCollapse={({ name }) => {
          return (
            name !== "root"
          )
        }}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        onEdit={(edit) => this.props.onEdit(edit)}
        onDelete={false}
        onSelect={false}
        theme={{
                base00: "white",
                base01: "#ddd",
                base02: "#ddd",
                base03: "#666",
                base04: "white",
                base05: "#666",
                base06: "#666",
                base07: "#666",
                base08: "#666",
                base09: "rgba(255,255,255, 1)",
                base0A: "rgba(255,255,255, 1)",
                base0B: "rgba(255,255,255, 1)",
                base0C: "rgba(255,255,255, 1)",
                base0D: "rgba(255,255,255, 1)",
                base0E: "rgba(255,255,255, 1)",
                base0F: "rgba(255,255,255, 1)"
            }}
        indentWidth={1}
        iconStyle="circle"
        // sortKeys={true}
        style={{background: 'transparent', fontSize: '13px', fontFamily: 'IBM Plex Mono'}}
        />
        )
  }
}
