export default {
  knobX: 40,
  knobY: 40,
  svg: `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100" height="100" viewBox="0 0 100 100">
        <defs>
            <path id="maskPath3" d="M0 0h100v50.5H0z"/>
        </defs>
        <g id="SVG"> 
            <g id="background">
            <circle id="backgroundCircle" fill="#444444" cx="50" cy="50" r="50"/>
            </g>
            <g id="progress">
            <mask id="mask-3" fill="white">
                <use xlink:href="#maskPath3"/>
            </mask>
            <circle id="progressCircle" cx="50" cy="50" r="40" fill="#FFFFFF" fill-rule="nonzero" mask="url(#mask-3)"/>
            </g>
            <g id="knob" transform="translate(9.5 9.738)">
                <circle id="knobCircle" cx="40.5" cy="40.262" r="40" fill="#FFFFFF" fill-rule="nonzero"/>
                <path id="knobLine" stroke="#000000" stroke-linecap="square" stroke-width="6.631" d="M40.5 -6v24"/>
            </g>
            <g id="label" fill="#000000" font-family="Helvetica" font-size="18.269" font-weight="normal" opacity=".249" transform="translate(1.923 38.462)">
            <text id="labelText">
                <tspan x="34.836" y="18.538">000</tspan>
            </text>
            </g>
        </g>
    </svg>
`,
  updateAttributes: [
    {
      element: '#label text tspan',
      content: (props, value) => {
        return value.toFixed(0)
      },
      attrs: [
        {
          name: 'text-anchor',
          value: (props, value) => {
            return 'middle'
          },
        },
        {
          name: 'x',
          value: (props, value) => {
            return '49'
          },
        },
      ],
    },
    {
      element: '#knob path',
      attrs: [
        {
          name: 'stroke',
          value: (props, value) => {
            return '' + props.bgactive + ''
          },
        },
      ],
    },
    {
      element: '#background circle',
      attrs: [
        {
          name: 'fill',
          value: (props, value) => {
            return '' + props.bg + ''
          },
        },
      ],
    },
    {
      element: '#progress circle',
      attrs: [
        {
          name: 'shape-rendering',
          value: (props, value) => {
            return 'geometricPrecision'
          },
        },
        {
          name: 'transform',
          value: (props, value) => {
            return 'rotate(0, 50, 50) translate(0, 0)'
          },
        },
        {
          name: 'stroke',
          value: (props, value) => {
            return '' + props.bgactive + ''
          },
        },
        {
          name: 'stroke-width',
          value: (props, value) => {
            return '20'
          },
        },
        {
          name: 'stroke-dasharray',
          value: (props, value) => {
            return '' + ((value * 0.95) / props.max) * 400 + '% 300%'
          },
        },
        {
          name: 'stroke-dashoffset',
          value: (props, value) => {
            return setProgress(value, props)
          },
        },
      ],
    },
  ],
}

// const circle = document.querySelector('#progress circle');
const radius = 50
const circumference = radius * 2 * Math.PI

function setProgress(value, props) {
  const percent = (value.toFixed(0) / props.max) * 100
  const offset = circumference - percent / 100
  return '"' + offset + '"'
}
