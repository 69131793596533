import React from 'react'
import { MIDI } from '../core'
import Draggable from 'react-draggable';

export class Fader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      values: [this.props.value || 0],
      value: this.props.defaultValue || 0,
      name: this.props.text || '0',
      channel: this.props.channel || 0,
      note: this.props.note || 0,
      bg: this.props.bg || 'var(--c-instrument-bg)',
      bgactive: this.props.bgactive || 'var(--c-instrument-active)',
      direction: this.props.direction || 'vertical',
      defaultValue: this.props.defaultValue || 0,
      padding: this.props.padding || '3em',
      paddingHalf: this.props.paddingHalf || '1.5em',
      newDate: new Date(),
    }
    this.onChange = this.onChange.bind(this)
    this.handleDrag = this.handleDrag.bind(this)
  }

  onChange(values) {
    this.setState({ values: values })
    const controlChangeValue = Math.round(parseInt(values))
    const channel = this.props.channel - 1
    const controlChangeCommand = parseInt('B0', 16)
    const controlChangeWithChannel = controlChangeCommand + channel
    const controlChangeHex = controlChangeWithChannel.toString(16)
    const controlChange = parseInt(controlChangeHex, 16)

    var newDate = new Date()
    var oldDate = this.state.newDate
    var timeDiff = (newDate - oldDate) / 1000
    if (timeDiff > 0.1) {
      this.setState({
        newDate: newDate,
      })
      return MIDI.send([controlChange, this.props.note, controlChangeValue])
    }
  }

  handleDrag = (e, ui) => {
    var midiValue =
    this.props.width < this.props.height ?
    Math.round(127 - (((ui.y - this.props.width/2) / (this.props.height - ui.y + this.props.width/2) ) * 127) )
    : this.props.width > this.props.height ?
    Math.round((ui.x/ (this.props.width - ui.x/2)) * 127)
    : null
    this.onChange(Math.min(Math.max(midiValue, 0), 127))
  };

  handleClick = (e) => {
    console.log(e, e.target)
  }

  render() {
    return (
      <div
        style={
          this.props.width > this.props.height ? {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            flexDirection: 'row',
            backgroundImage: 'linear-gradient(to right,transparent 0px, transparent '+ this.state.paddingHalf +', '+ this.props.bg +' '+ this.state.paddingHalf +', '+ this.props.bg +' calc('+ this.props.width +'px - '+ this.state.paddingHalf +'),transparent calc('+ this.props.width +'px - '+ this.state.paddingHalf+'),transparent 100%)',
            position: 'relative'
          } : {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            flexDirection: 'column',
            backgroundImage: 'linear-gradient(to bottom,transparent 0px, transparent '+ this.state.paddingHalf +', '+ this.props.bg +' '+ this.state.paddingHalf +', '+ this.props.bg +' calc('+ this.props.height +'px - '+ this.state.paddingHalf +'),transparent calc('+ this.props.height +'px - '+ this.state.paddingHalf+'),transparent 100%)',
            position: 'relative'
          }}
        onMouseDown={e => this.handleClick(e)}
      >
        <Draggable
          axis={this.props.width > this.props.height ? 'x' : 'y'}
          handle=".handle"
          defaultPosition={{
            x: this.props.width > this.props.height ? -parseInt(this.props.width)/2 + this.props.width/2 : 0, 
            y: this.props.width < this.props.height ? parseInt(this.props.height) - this.props.width - parseFloat(getComputedStyle(document.body).fontSize)*this.state.padding.substring(0, this.state.padding.length-2) : 0
          }}
          position={null}
          bounds={"parent"}
          grid={[1, 1]}
          scale={1}
          onStart={this.handleStart}
          onDrag={this.handleDrag}
          onStop={this.handleStop}
          >
          <div 
            className="handle"
            style={
              this.props.width > this.props.height ? 
              {
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                height: 'calc(100% + ' + this.state.padding + ')',
                width: 'calc('+ this.props.height + 'px + ' + this.state.padding + ')',
                height: 'calc('+ this.props.height + 'px + ' + this.state.padding + ')',
              } : 
              {
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                width: 'calc('+ this.props.width + 'px + ' + this.state.padding + ')',
                height: 'calc('+ this.props.width + 'px + ' + this.state.padding + ')',
            }}>
              <div 
                style={
                  this.props.width > this.props.height ? 
                  {
                    background: this.props.bgactive,
                    height: 'calc(100% - ' + this.state.padding + ')',
                    width: this.props.height
                  } : 
                  {
                    background: this.props.bgactive,
                    width: 'calc(100% - ' + this.state.padding + ')',
                    height: this.props.width
                }}>
              </div>
            </div>
        </Draggable>
      </div>
    )
  }
}
