import * as MIDI from './midi/MIDI'
import React from 'react'
import createStore from './midi/createStore'
import styled from 'react-emotion'
import { Switch, Route } from 'react-router-dom'
import { Observer } from 'mobx-react'
import { sortBy } from 'lodash'
import { AllthemidiIcon } from '../layout/LayoutIcons'
import { Modal, Space, Row, Col, Button, Divider } from 'antd'
import { logEvent } from '../utils/Firebase'

export class MainView extends React.Component {
  constructor(props) {
    super(props)
    const { features } = props
    this.store = createStore(features)
    const instruments = sortBy(
      [].concat(...features.map(f => f.instruments || [])),
      'sortKey'
    )
    this.instruments = instruments
  }

  handleTestflightLink() {
    logEvent('testflight-link')
    // Log Firebase event
    logEvent('testflight-link')
    window.open("https://testflight.apple.com/join/kODawhYm","_blank")
  }

  handleAppstoreLink() {
    logEvent('appstore-link')
    // Log Firebase event
    logEvent('appstore-link')
    window.open("https://apps.apple.com/de/app/allthemidi/id1516518842","_blank")
  }

  handlePortfolioLink() {
    // Log Firebase event
    logEvent('portfolio-link')
    window.open("https://sergimiral.com","_blank")
  }

  renderContent() {
    const landing = () => {
      return (
        <StyledModal
          title={null}
          visible={true}
          closable={false}
          footer={null}
          centered
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Row>
              <Col flex="100px">
                <AllthemidiIcon
                  style={{ fontSize: '5em', textAlign: 'center', marginBottom: '24px' }}
                />
              </Col>
              <Col flex="auto">
                <h2>allthemidi</h2>
                <p>Design your own custom MIDI controllers and send MIDI messages to other apps & hardware.</p>
                <Button style={{ color: '#0066ff', padding: '0', fontSize: '100%', height: 'auto' }} type="link" onClick={() => this.handleAppstoreLink()}>{'Download on the App Store.'}</Button>
              </Col>
            </Row>
          </Space>
        </StyledModal>
      )
    }
    const support = () => {
      return (
        <StyledModal
          title={null}
          visible={true}
          closable={false}
          footer={null}
          centered
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <h2>Support</h2>
            <div>
              <Divider orientation="left" plain style={{color: '#ffffff'}}>
                {'WHAT'}
              </Divider>
              <p style={{ fontSize: '13px' }}>
                {'Design your own custom MIDI controllers and send MIDI messages to other apps & hardware.'}
                </p>
            </div>
            <div>
              <Divider orientation="left" plain style={{color: '#ffffff'}}>
                {'HOW'}
              </Divider>
              <p style={{ fontSize: '13px' }}>
                {'This app works in combination with Audio Unit apps that listen for MIDI events. A great free app that allows sending MIDI messages to other apps & devices via Bluetooth or USB cable is Midimittr'}
                </p>
            </div>
            <div>
              <Divider orientation="left" plain style={{color: '#ffffff'}}>
                {'BASIC'}
              </Divider>
              <p style={{ fontSize: '13px' }}>
                {'The main MIDI properties for each component (channel, note, cc) as well as the customisation options (color, direction, text) can be changed using the controllers shown in the screen.'}
                </p>
            </div>
            <div>
              <Divider orientation="left" plain style={{color: '#ffffff'}}>
                {'ADVANCED'}
              </Divider>
              <p style={{ fontSize: '13px' }}>
                {'Inspecting the underlying data structure in json format will give you access to more tweaks. Check the existing templates to know more about some of the possibilities.'}
                </p>
            </div>
            <div>
              <Divider orientation="left" plain style={{color: '#ffffff'}}>
                {'HELP'}
              </Divider>
              <p style={{ fontSize: '13px' }}>
                {'If you need support with your layouts or have any feedback or ideas to improve the app just get in touch by email (allthemidi@gmail.com)'}
                </p>
            </div>
          </Space>
        </StyledModal>
      )
    }
    const privacyPolicy = () => {
      return (
        <StyledModal
          title={null}
          visible={true}
          closable={false}
          footer={null}
          centered
        >
          <Space direction="vertical" style={{ width: '100%' }}>
                <AllthemidiIcon
                  style={{ fontSize: '5em', textAlign: 'center', marginBottom: '24px' }}
                />
                <h1>Privacy Policy</h1> <p>
                  This SERVICE is provided and is intended for use as is.
                </p> <p>
                  This page is used to inform visitors regarding
                  policies about collection, use, and disclosure of Personal
                  Information if anyone decided to use this Service.
                </p> <p>
                  If you choose to use this Service, then you agree to
                  the collection and use of information in relation to this
                  policy.
                   {/* The Personal Information that its collected is
                  used for providing and improving the Service. It will not be used or shared with
                  anyone except as described in this Privacy Policy. */}
                </p> <p>
                  The terms used in this Privacy Policy have the same meanings
                  as in our Terms and Conditions, which is accessible at
                  allthemidi unless otherwise defined in this Privacy Policy.
                </p> <h3>Information Collection and Use</h3> 
                <p>
                    The app does not ask for personal information. This website uses <a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a> to help us improve the performance & overall experience of the Service.
                  </p> 
                  {/* <h3>Log Data</h3> <p>
                  Whenever you use this Service, in a case of an error in the app
                  there's data and information (through third party
                  products) on your phone called Log Data. This Log Data may
                  include information such as your device Internet Protocol
                  (“IP”) address, device name, operating system version, the
                  configuration of the app when utilizing the Service,
                  the time and date of your use of the Service, and other
                  statistics.
                </p> <h3>Cookies</h3> <p>
                  Cookies are files with a small amount of data that are
                  commonly used as anonymous unique identifiers. These are sent
                  to your browser from the websites that you visit and are
                  stored on your device's internal memory.
                </p> <p>
                  This Service does not use these “cookies” explicitly. However,
                  the app may use third party code and libraries that use
                  “cookies” to collect information and improve their services.
                  You have the option to either accept or refuse these cookies
                  and know when a cookie is being sent to your device. If you
                  choose to refuse our cookies, you may not be able to use some
                  portions of this Service.
                </p> <h3>Service Providers</h3> <p>
                  Third-party companies and
                  individuals might be employed due to the following reasons:
                </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
                  I want to inform users of this Service
                  that these third parties have access to your Personal
                  Information. The reason is to perform the tasks assigned to
                  them on our behalf. However, they are obligated not to
                  disclose or use the information for any other purpose.
                </p>  */}
                {/* <h3>Links to Other Sites</h3> <p>
                  This Service may contain links to other sites. If you click on
                  a third-party link, you will be directed to external sites with their own privacy policies.
                </p>  */}
                <h3>Changes to This Privacy Policy</h3> <p>
                  The Privacy Policy might be updated in the future. If this happens we will notify of any changes by posting the new Privacy Policy on
                  this page.
                </p> <p>This policy is effective as of 2020-08-30</p> <h3>Contact Us</h3> <p>
                  If you have any questions or suggestions about the
                  Privacy Policy, do not hesitate to contact by email at allthemidi@gmail.com.
                </p> 
                <p>
                <Button style={{ color: 'inherit', padding: '0', fontSize:'100%', height: 'auto', opacity: '0.5' }} type="link" onClick={() => this.handlePortfolioLink()}>{'— Sergi Miral, Berlin 2020'}</Button>
                </p>
                <h/>
                
          </Space>
        </StyledModal>
      )
    }
    return (
      <Switch>
        {this.instruments.map(instrument => {
          const Component = instrument.component
          return (
            <Route
              key={instrument.id}
              path={`/${instrument.id}`}
              component={() => <Component store={this.store} />}
            />
          )
        })}
        <Route path="/privacy-policy" component={privacyPolicy} />
        <Route path="/support" component={support} />
        <Route exact path="/" component={landing} />
      </Switch>
    )
  }

  buttonClick() {
    window.webkit.messageHandlers.scriptHandler.postMessage({
      name: 'example message from react to swift',
      value: 'test',
    })
  }

  render() {
    const MainContent = styled('div')``
    return (
      <div>
        <MainContent innerRef={element => (this.contentElement = element)}>
          {this.renderContent()}
        </MainContent>

        <Observer>
          {() => (
            <MIDIEmitter
              activeNotes={this.store.activeNotes}
              transpose={this.store.transpose}
              octave={this.store.octave}
              velocity={this.store.noteVelocity}
            />
          )}
        </Observer>
      </div>
    )
  }
}

class MIDIEmitter extends React.Component {
  constructor(props) {
    super(props)
    this.currentNotes = new Map()
  }
  handleNotes(props) {
    const activeNotes = props.activeNotes
    const currentNotes = this.currentNotes
    for (const note of activeNotes) {
      if (!currentNotes.has(note)) {
        const midiNote = note + props.transpose + props.octave * 12
        const velocity = props.velocity
        MIDI.send([0x90, midiNote, velocity])
        currentNotes.set(note, { midiNote, velocity })
      }
    }
    for (const note of currentNotes.keys()) {
      if (!activeNotes.has(note)) {
        const data = currentNotes.get(note)
        MIDI.send([0x80, data.midiNote, data.velocity])
        currentNotes.delete(note)
      }
    }
  }
  componentDidMount() {
    this.handleNotes(this.props)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleNotes(nextProps)
  }
  render() {
    return null
  }
}

export default MainView

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background: transparent;
  }
`