import React from 'react'
import * as MIDI from './MIDI'
import { Select } from 'antd'


class SelectMidiInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      val: '',
      inputs: MIDI.getInputs(),
    }
  }

  // UNSAFE_componentWillMount() {
  //   if (window.webkit) {
  //   } else {
  //     if (this.state.val === '') {
  //       for (let i = 0; i < this.state.inputs.length; i++) {
  //         if (this.state.inputs[i].name === 'Network Session 1') {
  //           this.handleOptionSelect(this.state.inputs[i].key)
  //           this.handleChange(this.state.inputs[i].key)
  //         }
  //       }
  //     }
  //   }
  // }

  handleChange(value) {
    MIDI.selectInput(value)
    console.log('MIDI input: ' + value)
  }

  handleOptionSelect(key) {
    this.setState({
      val: key,
    })
  }

  render() {
    let inputs = this.state.inputs
    return (
      <Select
        onChange={this.handleChange}
        style={{ width: '100%' }}
        placeholder={'MIDI Input'}
        onMouseDown={e => {
          e.preventDefault()
          return false
        }}
      >
        {inputs.map(input => (
          <Select.Option
            key={input.key}
            value={input.key}
            onSelect={() => this.handleOptionSelect(input.key)}
          >
            {input.name}
          </Select.Option>
        ))}
      </Select>
    )
  }
}

export default SelectMidiInput
